import React, { Component } from 'react';
import { connect } from 'react-redux';

import ArticleDetailsWrapper from '../components/ArticleDetailsWrapper';
import { selectors } from '../reducers/appReducer';
// import { selectors } from '../reducers/itemDataCategoriesReducer';

export class ArticleDetailsContainer extends Component {
    render() {
        return <ArticleDetailsWrapper {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        // productInfoMap: selectors.getProductInfoMap(state),
        productProperties: selectors.getProductProperties(state),
        language: selectors.getLanguage(state)
        // itemDataCategories: selectors.getAllCategories(state)
        // itemDataChosenCategories: selectors.getChosenCategories(state),
        // itemDataChosenCategoriesId: selectors.getChosenCategoriesId(state)
    };
}

export default connect(mapStateToProps)(ArticleDetailsContainer);
