import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DropdownList from './DropdownList';

class DropdownListFilterWrapper extends Component {
    getItems() {
        const { filter, validFilterOptions } = this.props;

        if (validFilterOptions === null) {
            return filter.options.sortBy(o => this.props.itemToString(o).toLowerCase()).toJS();
        }

        return filter.options
            .filter(o => validFilterOptions.includes(o.value))
            .sortBy(o => this.props.itemToString(o).toLowerCase())
            .toJS();
    }

    render() {
        const { filter, onChange, placeholder, validFilterOptions, disabled, ...rest } = this.props;

        return (
            <DropdownList
                {...rest}
                items={this.getItems()}
                placeholder={placeholder}
                selectedItem={filter.value}
                disabled={disabled || !filter.enabled}
                onChange={item => onChange(item)}
            />
        );
    }
}

DropdownListFilterWrapper.propTypes = {
    disabled: PropTypes.bool,
    filter: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    validFilterOptions: PropTypes.object
};

DropdownListFilterWrapper.defaultProps = {
    disabled: false,
    validFilterOptions: null
};

export default DropdownListFilterWrapper;
