import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';
import Highlighter from 'react-highlight-words';
import { Trans, NumberFormat } from '@lingui/react';
import format from 'date-fns/format';
import get from 'lodash/get';
import cx from 'classnames';

import LimitText from './utils/LimitText';
import { getAssetURL } from '../api';
import noPhotoImg from '../images/no-photo.png';
import { ArticleDetailsModal } from './ArticleDetailsModal';
import { getLocaleFromLanguageCode } from '../intl-helpers';

function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

class RowBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };
    }

    getValiditaPrezzo() {
        const { item } = this.props;

        if (item.prezzo_attuale && item.prezzo_attuale.data_inizio_validita_prezzo) {
            return format(item.prezzo_attuale.data_inizio_validita_prezzo.date, 'DD/MM/YYYY');
        }

        return null;
    }

    openModal = () => {
        this.setState({
            showModal: true
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    render() {
        const {
            index,
            item,
            // productInfoMap,
            language,
            onSelect,
            q,
            selectable
        } = this.props;

        // console.log(item.toJS());

        const locale = getLocaleFromLanguageCode(language);

        return (
            <Fragment>
                {this.state.showModal && (
                    <ArticleDetailsModal article={item} onClose={this.closeModal} />
                )}
                <div
                    className={cx(
                        'card article-block-item c-zoom-in',
                        isOdd(index) ? 'bg-gray' : 'bg-white'
                    )}
                    onClick={this.openModal}
                >
                    <div className="card-image-wrapper">
                        <div className="card-image">
                            <div className="table-img-preview" style={{ height: '130px' }}>
                                <Img
                                    src={
                                        item.immagine_prodotto
                                            ? getAssetURL(item.immagine_prodotto.url)
                                            : noPhotoImg
                                    }
                                    className="img-responsive m-a"
                                    loader={
                                        <div className="rounded bg-secondary table-img-preview" />
                                    }
                                    style={{
                                        maxHeight: '125px'
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-image-side">
                            {selectable && (
                                <div
                                    className="float-right"
                                    style={{
                                        position: 'relative',
                                        top: '-8px',
                                        left: '10px'
                                    }}
                                >
                                    <span className="form-group">
                                        <label
                                            className="form-checkbox form-checkbox-inline"
                                            onClick={e => {
                                                e.stopPropagation();
                                                onSelect(item.id);
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={item.in_collection}
                                                onClick={e => e.stopPropagation()}
                                                onChange={() => {}}
                                            />
                                            <i className="form-icon" />
                                        </label>
                                    </span>
                                </div>
                            )}
                            <div className="text-label text-uppercase text-small">
                                <Trans id="um" />
                            </div>
                            <div
                                className="tooltip"
                                data-tooltip={
                                    item.unita_ordine.name && item.unita_ordine.name[locale]
                                        ? item.unita_ordine.name[locale]
                                        : ''
                                }
                            >
                                {item.unita_ordine.code}
                            </div>
                            <hr className="hr-light" />
                            <div className="text-label text-uppercase text-small">
                                <Trans id="conf" />
                            </div>
                            {item.quantita_contenuta ? item.quantita_contenuta : '-'}
                        </div>
                    </div>
                    <div className="card-header">
                        <div className="card-title h6">
                            <Highlighter searchWords={[q]} textToHighlight={item.codice_articolo} />
                        </div>
                        <div className="card-subtitle">
                            <LimitText
                                limit={25}
                                highlight={q}
                                text={get(item, `descrizione_articolo[${locale}]`, '')}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-body-wrapper">
                            <div className="text-label text-uppercase text-small">
                                <Trans id="brand" />
                            </div>
                            <LimitText limit={20} text={item.marca.name} />
                            <div className="text-label text-uppercase text-small">
                                <Trans id="series" />
                            </div>
                            <LimitText
                                limit={23}
                                text={
                                    item.serie.length > 0
                                        ? item.serie.map(l => l.name[locale]).join(' / ')
                                        : '-'
                                }
                            />
                            <div className="text-label text-uppercase">
                                <Trans id="line" />
                            </div>
                            <LimitText
                                limit={23}
                                text={
                                    item.linee.length > 0
                                        ? item.linee.map(l => l.name[locale]).join(' / ')
                                        : '-'
                                }
                            />
                            <div className="text-label text-uppercase text-small">
                                <Trans id="model" />
                            </div>
                            <LimitText
                                limit={23}
                                text={get(item, `modello.name[${locale}]`, '-')}
                            />
                        </div>
                    </div>
                    <div className="card-footer">
                        {item.prezzo !== null ? (
                            <Fragment>
                                <div className="table-price-lg">
                                    €{' '}
                                    <NumberFormat
                                        value={item.prezzo}
                                        format={{ minimumFractionDigits: 4 }}
                                    />
                                </div>
                                <div className="table-date-sm text-error">
                                    {this.getValiditaPrezzo() !== null && (
                                        <Fragment>
                                            <Trans id="from" /> {this.getValiditaPrezzo()}
                                        </Fragment>
                                    )}
                                </div>
                            </Fragment>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

RowBlock.propTypes = {
    bg: PropTypes.string,
    index: PropTypes.number,
    onCloseButtonClick: PropTypes.func,
    onSelect: PropTypes.func,
    showDetails: PropTypes.bool
};

export default RowBlock;
