export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEMS_START = 'FETCH_ITEMS_START';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAIL = 'FETCH_ITEMS_FAIL';
export const RESET_ITEMS = 'RESET_ITEMS';
export const CHANGE_ITEMS_PAGE = 'CHANGE_ITEMS_PAGE';
export const SORT_ITEMS_BY = 'SORT_ITEMS_BY';
export const SET_LAST_SEARCH_HASH = 'SET_LAST_SEARCH_HASH';
export const FETCH_COLLECTION_ITEMS = 'FETCH_COLLECTION_ITEMS';

export function fetchItems(options = {}) {
    return {
        type: FETCH_ITEMS,
        payload: options
    };
}

export function fetchItemsStart() {
    return {
        type: FETCH_ITEMS_START
    };
}

export function fetchItemsSuccess(response) {
    return {
        type: FETCH_ITEMS_SUCCESS,
        payload: response.data
    };
}

export function fetchItemsFail(response) {
    return {
        type: FETCH_ITEMS_FAIL
        // TODO: portare errore nel payload
    };
}

export function changePage(page) {
    return {
        type: CHANGE_ITEMS_PAGE,
        payload: page
    };
}

export function sortBy(attr) {
    return {
        type: SORT_ITEMS_BY,
        payload: attr
    };
}

export function resetItems() {
    return {
        type: RESET_ITEMS
    };
}

export function setLastSearchHash(hash) {
    return {
        type: SET_LAST_SEARCH_HASH,
        payload: hash
    };
}

export function fetchCollectionItems() {
    return {
        type: FETCH_COLLECTION_ITEMS
    };
}

export const actions = {
    fetchItems,
    fetchItemsStart,
    fetchItemsSuccess,
    fetchItemsFail,
    resetItems,
    changePage,
    sortBy,
    setLastSearchHash,
    fetchCollectionItems
};

export const actionTypes = {
    FETCH_ITEMS,
    FETCH_ITEMS_START,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEMS_FAIL,
    RESET_ITEMS,
    CHANGE_ITEMS_PAGE,
    SORT_ITEMS_BY,
    SET_LAST_SEARCH_HASH,
    FETCH_COLLECTION_ITEMS
};
