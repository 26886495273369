import React, { Component } from 'react';
import { withI18n } from '@lingui/react';
import Swal from 'sweetalert2';

export class CollectionControlsHeader extends Component {
    handleDelete = e => {
        e.stopPropagation();

        const { i18n } = this.props;

        Swal.fire({
            type: 'warning',
            text: i18n._('collection:confirm:remove:all:page:articles'),
            showCancelButton: true,
            confirmButtonText: i18n._('confirm'),
            cancelButtonText: i18n._('cancel')
        }).then(res => {
            if (res.value === true) {
                this.props.onRemoveAll();
            }
        });
    };

    render() {
        const { selectAllDisabled, onSelectAll, i18n } = this.props;

        return (
            <th style={{ width: '65px' }}>
                <span className="form-group">
                    <label
                        className="form-checkbox form-checkbox-inline float-left tooltip"
                        data-tooltip={i18n._('Add all page articles')}
                        style={{ top: 0, paddingRight: 0 }}
                        onClick={e => {
                            e.stopPropagation();
                            if (selectAllDisabled === false) {
                                onSelectAll();
                            }
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={true}
                            onClick={e => e.stopPropagation()}
                            onChange={() => {}}
                            disabled={selectAllDisabled}
                        />
                        <i className="form-icon" />
                    </label>
                </span>
                <span className="float-left">
                    <span
                        className="tooltip"
                        data-tooltip={i18n._(
                            'Remove all page articles from the collection'
                        )}
                    >
                        <i
                            className="icon icon-cross text-error c-hand"
                            onClick={this.handleDelete}
                        />
                    </span>
                </span>
            </th>
        );
    }
}

export default withI18n()(CollectionControlsHeader);
