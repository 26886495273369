import React, { Component } from 'react';
import InfiniteTree from 'react-infinite-tree';
import { withI18n, i18nMark, Trans } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Record, List, Seq } from 'immutable';
import TreeUtils from 'immutable-treeutils';
import { toast } from 'react-toastify';
import cx from 'classnames';
// import includes from 'lodash/includes';
import { includes, uniq, intersection, filter } from 'lodash';

import { getAllProductProperties, updateGroup, createGroup } from '../api';
import InputField from './form/InputField';

const validate = data => {
    let errors = {};

    if (!data.code) {
        errors.code = i18nMark('validation:error:mandatory:field');
    }

    if (!data.label) {
        errors.label = i18nMark('validation:error:mandatory:field');
    }

    return errors;
};

const NodeRecord = Record({
    id: null,
    name: null,
    code: null,
    children: List([])
});

const treeUtils = new TreeUtils(Seq(), 'id', 'children');

function createTreeFromData(data) {
    let root = List([]);

    // console.log(data);

    // Popolo gli elementi di root
    data.forEach(prop => {
        if (prop.parent_id === null && !includes(['old_assets', 'ordinamenti'], prop.code)) {
            root = root.push(
                NodeRecord({
                    id: prop.id,
                    name: prop.label.it_IT,
                    code: prop.code
                })
            );
        }
    });

    let tree = NodeRecord({
        id: 'root',
        children: root
    });

    // Popolo tutto il resto
    data.forEach(prop => {
        if (prop.parent_id !== null) {
            const path = treeUtils.byId(tree, prop.parent_id);

            if (path) {
                let node = tree.getIn(path);

                node = node.set(
                    'children',
                    node.children.push(
                        NodeRecord({
                            id: prop.id,
                            name: prop.label.it_IT,
                            code: prop.code
                        })
                    )
                );

                tree = tree.setIn(path, node);
            }
        }
    });

    return tree.get('children');
}

function getSelectedNodesFromGroup(group) {
    if (!group.id) {
        return [];
    }

    if (group.rules.length === 0) {
        return [];
    }

    return group.rules[0].properties;
}

export class UserGroupEditModal extends Component {
    tree = null;

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            treeData: null,
            selectedNodes: getSelectedNodesFromGroup(props.group)
        };
    }

    async componentDidMount() {
        const { data } = await getAllProductProperties();

        const treeData = createTreeFromData(data);

        this.setState({
            isFetching: false,
            treeData
        });

        if (this.tree) {
            this.tree.loadData(treeData.toJS());
        }
    }

    handleItemClick = node => {
        const { selectedNodes } = this.state;

        let op = 'select';

        if (includes(selectedNodes, node.code)) {
            op = 'deselect';
        }

        const nodes = [node.code];

        if (node.hasChildren()) {
            node.getChildren().forEach(childNode => {
                nodes.push(childNode.code);
                if (childNode.hasChildren()) {
                    childNode.getChildren().forEach(grandChildNode => {
                        nodes.push(grandChildNode.code);
                    });
                }
            });
        }

        if (op === 'select') {
            const parent = node.getParent();

            // Se l'azione è di selezionare aggiungo il padre alla lista dei nodi selezionati
            if (parent) {
                nodes.push(parent.code);

                // FIXME: sarebbe da fare la stessa cosa anche quando deseleziono un nodo, dovrei verificare che anche il suo "nonno"
                // abbia dei figli selezionati
                const grandParent = parent.getParent();

                if (grandParent) {
                    nodes.push(grandParent.code);
                }
            }
        } else {
            const parent = node.getParent();

            // Altrimenti verifico se il padre ha altri nodi selezionati, in caso contrario lo deseleziono
            if (parent) {
                const childCodes = parent.getChildren().map(childNode => {
                    return childNode.code;
                });

                const matches = intersection(selectedNodes, childCodes);

                // matches sarà sempre almeno 1 perchè nei selectedNodes è incluso per lo meno il nodo che sto deselezionando adesso,
                // quindi per caprie se ci sono altri nodi selezionati devo trovarne 2 o più
                if (matches.length < 2) {
                    nodes.push(parent.code);
                }
            }
        }

        //console.log(selectedNodes);
        //console.log(nodes);

        const newSelectedNodes =
            op === 'select'
                ? [].concat(selectedNodes, nodes)
                : filter(selectedNodes, n => !includes(nodes, n));

        //console.log(uniq(newSelectedNodes));

        this.setState({
            selectedNodes: uniq(newSelectedNodes)
        });

        // this.tree.selectNode(node);
    };

    onSubmit = async data => {
        const { id } = this.props.group;
        const { i18n } = this.props;

        // console.log(data);

        const isUpdating = id !== null;

        const payload = {
            ...data,
            rules: [
                {
                    type: 'include',
                    target: 'properties',
                    properties: this.state.selectedNodes
                }
            ]
        };

        const xhr = isUpdating ? updateGroup(id, payload) : createGroup(payload);

        try {
            await xhr;
        } catch (err) {
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                if (err.response.status === 412) {
                    errorMessage = i18n._('fill:required:fields');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage
            };
        }

        /*if (isUpdating) {
            this.props.updateCollectionSuccess(res);
        } else {
            this.props.fetchCollections();
        }*/

        const msg = isUpdating ? i18n._('userGroup:updated') : i18n._('userGroup:created');

        toast(msg, {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS
        });

        this.props.onClose();
    };

    render() {
        const { onClose, group } = this.props;

        const isCreating = group.id === null;

        return (
            <div className="modal active">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container">
                    <div className="modal-header">
                        <div className="modal-title h5">
                            {group.id ? `Modifica "${group.label}"` : 'Crea nuovo gruppo'}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <Form
                        onSubmit={this.onSubmit}
                        validate={validate}
                        initialValues={{ code: group.code, label: group.label }}
                        render={({
                            handleSubmit,
                            submitting
                            /*form
                            pristine,
                            hasValidationErrors,
                            submitError,
                            errors,
                            touched,
                            submitSucceeded,*/
                        }) => {
                            // this.form = form;

                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className="form-horizontal"
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    <div className="modal-body">
                                        <div className="column col-12">
                                            <Field
                                                name="code"
                                                component={InputField}
                                                placeholder={i18nMark('code')}
                                                className="form-input input-md my-2"
                                                layout="horizontal"
                                            />
                                            <Field
                                                name="label"
                                                component={InputField}
                                                placeholder={i18nMark('label')}
                                                className="form-input input-md my-2"
                                                layout="horizontal"
                                            />
                                            <h6>
                                                <Trans id="rules" />
                                            </h6>
                                            {this.state.isFetching && (
                                                <div>
                                                    <Trans id="loading" />
                                                    ...
                                                </div>
                                            )}
                                            <InfiniteTree
                                                ref={node => (this.tree = node ? node.tree : null)}
                                                autoOpen={false}
                                                selectable={true}
                                                height={this.state.isFetching ? 100 : 500}
                                                width="100%"
                                                rowHeight={24}
                                                rowRenderer={({ tree, node }) => {
                                                    const { id, name, code, state } = node;
                                                    const { depth, open } = state;
                                                    const { options } = tree;
                                                    const more = node.hasChildren();

                                                    // const isSelected = this.props.selectedItem === id;
                                                    const isSelected = false;

                                                    return (
                                                        <div
                                                            className={cx('infinite-tree-item', {
                                                                'bg-secondary': isSelected
                                                            })}
                                                            data-id={id}
                                                        >
                                                            <div
                                                                className="infinite-tree-node"
                                                                style={{ marginLeft: depth * 18 }}
                                                            >
                                                                {more && open && (
                                                                    <span
                                                                        className={cx(
                                                                            options.togglerClass,
                                                                            'c-hand'
                                                                        )}
                                                                        onClick={() =>
                                                                            tree.closeNode(node)
                                                                        }
                                                                    >
                                                                        <i className="icon icon-small icon-arrow-down mr-1 text-primary" />
                                                                    </span>
                                                                )}
                                                                {more && !open && (
                                                                    <span
                                                                        className={cx(
                                                                            options.togglerClass,
                                                                            'c-hand',
                                                                            'infinite-tree-closed'
                                                                        )}
                                                                        onClick={() =>
                                                                            tree.openNode(node)
                                                                        }
                                                                    >
                                                                        <i className="icon icon-small icon-plus mr-1 text-primary" />
                                                                    </span>
                                                                )}
                                                                {!more && (
                                                                    <span
                                                                        className={cx(
                                                                            options.togglerClass,
                                                                            'infinite-tree-closed'
                                                                        )}
                                                                    >
                                                                        <i className="icon icon-small icon-minus mr-1 text-primary" />
                                                                    </span>
                                                                )}
                                                                <span
                                                                    className={cx(
                                                                        'infinite-tree-title'
                                                                    )}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: '-5px',
                                                                            marginRight: '2px'
                                                                        }}
                                                                        checked={includes(
                                                                            this.state
                                                                                .selectedNodes,
                                                                            code
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleItemClick(
                                                                                node
                                                                            )
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={
                                                                            isSelected
                                                                                ? 'infinite-tree-leaf text-bold'
                                                                                : 'infinite-tree-leaf'
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                            disabled={submitting}
                                        >
                                            {isCreating ? (
                                                <Trans id="confirm:create" />
                                            ) : (
                                                <Trans id="confirm:update" />
                                            )}
                                        </button>
                                    </div>
                                </form>
                            );
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withI18n()(UserGroupEditModal);
