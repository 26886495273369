import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import remove from 'lodash/remove';
import tail from 'lodash/tail';
// import forOwn from 'lodash/forOwn';
import * as c from '../constants';
import format from 'date-fns/format';

import { getAssetURL } from '../api';
import Modal from './utils/Modal';
import ItemDataCategoryBody from './ItemDataCategoryBody';
import ArticleDataGalleryModal from './ArticleDataGalleryModal';
import { getIntlNumberFormat, getLocaleFromLanguageCode } from '../intl-helpers';
import { formatKeyValue } from '../formatters';

const baseIntl = getIntlNumberFormat();
const numberIntl = getIntlNumberFormat('it-IT', {
    minimumFractionDigits: 4
});

// function getExt(filename) {
//     return filename.split('.').pop();
// }

function getDetailValue(detail, prop, locale, productProperties, i18n) {
    // console.log(detail);
    // console.log(prop);

    if (typeof detail === 'undefined') {
        return null;
    }

    // FIXME: Sarebbe di type "collection" ma deve funzionare in maniera diversa dallo standard, come da richiesta
    if (prop.code === 'custom_classifications') {
        return detail.children
            .map(item => {
                return getDetailValue(
                    item,
                    productProperties[item.property],
                    locale,
                    productProperties
                );
            })
            .join('<br/>');
    }

    if (prop.type === 'collection') {
        if (detail.value === null && detail.children && detail.children.length > 0) {
            return detail.children
                .map(item => {
                    // Ricorsivo
                    return getDetailValue(
                        item,
                        productProperties[item.property],
                        locale,
                        productProperties
                    );
                })
                .join(' / ');
        }

        if (detail.value) {
            // TODO: da verificare con altri tipi di dato...
            return sortBy(detail.value, 'descrizione')
                .map(v => (isObject(v.descrizione) ? v.descrizione[locale] : v.descrizione))
                .join(' / ');
        }
    }

    if (detail.value === null) {
        return null;
    }

    // TODO: da rimuovere non appena il mode "prezzo" verra pubblicato in produzione
    if (prop.code === 'prezzo_listino' || prop.code === 'prezzo_articolo_spallettizzato') {
        return numberIntl.format(detail.value);
    }

    if (prop.type === 'boolean') {
        const s = detail.value === false ? i18n._('no') : i18n._('yes');

        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    if (prop.type === 'single_option') {
        return detail.value.descrizione;
    }

    if (prop.mode === 'marca') {
        return detail.value.sigla_marca;
    }

    if (prop.mode === 'prezzo') {
        return numberIntl.format(detail.value);
    }

    if (prop.type === 'float' || prop.type === 'float_metric_value') {
        return baseIntl.format(detail.value);
    }

    if (prop.type === 'datetime' || prop.type === 'date') {
        return format(detail.value, 'DD/MM/YYYY');
    }

    if (prop.type === 'key_value_collection') {
        return formatKeyValue(detail.value);
    }

    if (prop.mode === 'territorio_collection') {
        return detail.value
            .map(record => record.label[locale].value)
            .sort()
            .join(' / ');
    }

    // FIXME: Sarebbe di type "choice" ma deve funzionare in maniera diversa dallo standard, come da richiesta
    if (prop.code === 'sigla_marca') {
        return detail.value.code;
    }

    if (prop.type === 'choice') {
        return isObject(detail.value.label)
            ? get(detail.value.label, locale, detail.value.label.key)
            : detail.value.label;
    }

    if (prop.type === 'localized_string' || prop.type === 'localized_text') {
        return get(detail.value, locale, detail.value.key);
    }

    if (prop.type === 'free_choice') {
        return get(detail.value.label, locale, detail.value.label.key);
    }

    if (prop.type === 'classification') {
        const classificationTree = detail.value.map(leaf => {
            return get(leaf, `label.${locale}`, `[${leaf.code}]`);
        });

        const classificationLeafs = tail(classificationTree).join(' / ');

        return `${classificationTree[0]}: ${classificationLeafs}`;
    }

    return detail.value;
}

function getEtimLabel(etim_features, id, locale) {
    return etim_features[id].label[locale] || etim_features[id].label.key;
}

function getEtimValue(etim_features, feature, locale, i18n) {
    const currentFeature = etim_features[feature.id];

    // console.log(currentFeature);

    switch (currentFeature.type) {
        case 'alphanumeric':
            return get(feature, `data.label[${locale}]`);
        case 'logic':
            return feature.data === false ? i18n._('no') : i18n._('yes');
        case 'numeric':
            let result = baseIntl.format(feature.data);

            if (currentFeature.etim_unit_of_measure) {
                result = `${result} ${currentFeature.etim_unit_of_measure.description[locale]}`;
            }

            return result;
        case 'range':
            let resultR = `${feature.data[0]} - ${feature.data[1]}`;

            if (currentFeature.etim_unit_of_measure) {
                resultR = `${resultR} ${currentFeature.etim_unit_of_measure.description[locale]}`;
            }

            return resultR;
        default:
            return feature.data;
    }
}

class ItemDataCategory extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            // activeItems: [],
            showModal: false,
            // currentCategory: null, // Necessario???
            startingIndex: null,
            selectedFilter: {
                value: 'all',
                label: props.i18n._('all')
            },
            extFilter: {
                value: 'all',
                label: props.i18n._('all')
            }
        };
    }

    // componentDidMount() {
    //     this.setState({
    //         activeItems: this.props.categories.map((c, i) => i)
    //     });
    // }

    getAttributes() {
        const { category, details, productProperties, etimClassDetails, i18n } = this.props;

        const locale = this.getLocale();

        // console.warn(productProperties);

        if (category.type === c.SECTION_TYPE_FILES || category.type === c.SECTION_TYPE_IMAGES) {
            // console.warn(details.assets);
            // console.warn(category.key);

            return Object.values(details.assets[category.key]).map(asset => {
                return {
                    asset_classes: asset.asset_classes,
                    label: asset.asset_classes[0] ? asset.asset_classes[0].label[locale] : '-',
                    // FIXME: gestire fallback per descrizione se non presente locale
                    description: asset.description ? asset.description[locale] : '-',
                    size: asset.size,
                    filename: asset.url,
                    ext: asset.extension,
                    src: getAssetURL(asset.url)
                };
            });

            // return details.assets[category.key].map(id => {
            //     const file = details.assets[category.key].entities.asset[id];

            //     const assetClassId = file.asset_classes[0];

            //     return {
            //         asset_classes: file.asset_classes,
            //         label: assetClassId
            //             ? details.assets[category.key].entities.asset_classes[
            //                   assetClassId
            //               ].description
            //             : '-',
            //         size: file.weight,
            //         filename: file.filename,
            //         ext: file.extension,
            //         src: getAssetURL(file.url)
            //     };
            // });
        }

        // console.warn(category, details);

        // if (category.key === 'caratteristiche') {
        //     return this.props.item.features;
        // }

        let res = [];

        if (category.isFeatures) {
            res = Object.values(details.etim.etim_features).map(feature => {
                // console.warn(feature);
                // console.warn(category.info.etim_features);
                return {
                    key: feature.id,
                    label: getEtimLabel(etimClassDetails.etim_features, feature.id, locale),
                    value: getEtimValue(etimClassDetails.etim_features, feature, locale, i18n)
                };
            });

            res = sortBy(res, 'label');
        } else {
            if (category.key === c.SECTION_KEY_CORRELATI) {
                return details[category.key];
            } else if (category.type === c.SECTION_TYPE_REPEATABLE) {
                const categoryData = get(details, category.key, []);

                // console.warn(categoryData);
                // console.warn(category);

                res = categoryData.map((row, i) => {
                    return Object.values(row).map(prop => {
                        const productProp = productProperties[prop.property];

                        // console.warn(productProp);

                        return {
                            key: prop.property,
                            label: productProp.label[locale],
                            value: getDetailValue(
                                prop,
                                productProp,
                                locale,
                                productProperties,
                                i18n
                            )
                        };
                    });
                    // return Object.values(category.info).map(prop => {
                    //     const detail = get(row, prop.property_code);

                    //     return {
                    //         key: prop.property_code,
                    //         label: prop.label,
                    //         value: getDetailValue(detail, prop)
                    //     };
                    // });
                });
            } else {
                let properties = [];

                if (details[category.key]) {
                    properties = Object.values(details[category.key]).map(prop => {
                        const productProp = productProperties[prop.property];

                        let value = getDetailValue(
                            prop,
                            productProp,
                            locale,
                            productProperties,
                            i18n
                        );

                        // FIXME: workaround richiesto per riportare il valore della sigla marca se la descrizione marca é nulla
                        if (prop.property === 'descrizione_marca' && value === null) {
                            value = get(
                                details,
                                `[${category.key}].sigla_marca.value.label[${locale}]`
                            );
                        }

                        return {
                            key: prop.property,
                            label: productProp.label[locale],
                            value
                        };
                    });
                }

                const taxonomy = find(properties, { key: 'idrolab_taxonomy' });

                if (taxonomy && taxonomy.value) {
                    properties.push({
                        key: 'idrolab_taxonomy_sector',
                        label: i18n._('sector'),
                        value:
                            taxonomy.value.sector.code +
                            ' - ' +
                            get(taxonomy, `value.sector.label[${locale}]`, '')
                    });

                    properties.push({
                        key: 'idrolab_taxonomy_macrofamily',
                        label: i18n._('macrofamily'),
                        value:
                            taxonomy.value.macrofamily.code +
                            ' - ' +
                            get(taxonomy, `value.macrofamily.label[${locale}]`, '')
                    });

                    properties.push({
                        key: 'idrolab_taxonomy_family',
                        label: i18n._('family'),
                        value:
                            taxonomy.value.family.code +
                            ' - ' +
                            get(taxonomy, `value.family.label[${locale}]`, '')
                    });

                    remove(properties, { key: 'idrolab_taxonomy' });
                }

                return properties;

                // res = Object.values(category.info).map(prop => {
                //     const detail = get(
                //         details,
                //         `${category.key}.${prop.property_code}`
                //     );

                //     return {
                //         key: prop.property_code,
                //         label: prop.label,
                //         value: getDetailValue(detail, prop)
                //     };
                // });
            }
        }

        // const res = category.field_list.map(field => {
        //     return {
        //         key: field.key,
        //         label: field.label,
        //         value: this.props.item[field.key]
        //     };
        // });

        return res;
    }

    getFilterOptions() {
        const { category, details, language } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        if (category.type === c.SECTION_TYPE_FILES || category.type === c.SECTION_TYPE_IMAGES) {
            if (details.assets[category.key].length === 0) {
                return [];
            }

            const options = details.assets[category.key].reduce((assetClasses, asset) => {
                asset.asset_classes.forEach(klass => {
                    assetClasses[klass.code] = {
                        value: klass.code,
                        label: klass.label[locale]
                    };
                });

                return assetClasses;
            }, {});

            return [].concat(
                {
                    value: 'all',
                    label: this.props.i18n._('all')
                },
                ...Object.values(options)
            );
        }

        if (category.key === c.SECTION_KEY_CORRELATI) {
            const types = details[c.SECTION_KEY_CORRELATI].map(c => c.type);

            const options = uniq(types)
                .sort()
                .map(t => {
                    return {
                        value: t,
                        label: t
                    };
                });

            return [].concat(
                {
                    value: 'all',
                    label: this.props.i18n._('all')
                },
                ...options
            );
        }

        return [];
    }

    getExtOptions(category, attributes) {
        if (category.type !== c.SECTION_TYPE_IMAGES) {
            return [];
        }

        let allExts = [];

        attributes.forEach(attribute => {
            allExts.push(attribute.ext);
        });

        const options = uniq(
            allExts.map(ext => ({
                value: ext,
                label: ext.toUpperCase()
            }))
        );

        return [].concat(
            {
                value: 'all',
                label: this.props.i18n._('all')
            },
            ...options
        );
    }

    getLocale() {
        return getLocaleFromLanguageCode(this.props.language);
    }

    handleFilterChange = option => {
        this.setState({
            selectedFilter: option
        });
    };

    handleExtFilterChange = option => {
        this.setState({
            extFilter: option
        });
    };

    // handleAccordionChange = activeItems => {
    //     this.setState({
    //         activeItems
    //     });
    // };

    showModal = index => {
        this.setState({
            showModal: true,
            // currentCategory: category,
            startingIndex: index
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            // currentCategory: null,
            startingIndex: null
        });
    };

    render() {
        const {
            showModal,
            /*currentCategory,*/ startingIndex,
            selectedFilter,
            extFilter
        } = this.state;

        const { category } = this.props;

        const attributes = this.getAttributes();

        return (
            <Fragment>
                <Modal isOpen={showModal} modalRootClass="modal-ultrawide">
                    <ArticleDataGalleryModal
                        items={this.getAttributes()}
                        startingIndex={startingIndex}
                        onClose={this.closeModal}
                    />
                </Modal>
                <ItemDataCategoryBody
                    attributes={attributes}
                    filterOptions={this.getFilterOptions()}
                    selectedFilter={selectedFilter}
                    extFilter={extFilter}
                    extOptions={this.getExtOptions(category, attributes)}
                    category={category}
                    onFileClick={this.showModal}
                    onFilterChange={this.handleFilterChange}
                    onExtFilterChange={this.handleExtFilterChange}
                />
            </Fragment>
        );
    }
}

ItemDataCategory.propTypes = {
    item: PropTypes.object,
    category: PropTypes.object,
    language: PropTypes.string
};

export default withI18n()(ItemDataCategory);
