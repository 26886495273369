import React, { Component } from 'react';
import { connect } from 'react-redux';

import ArticleTableFilters from '../components/ArticleTableFilters';
import { selectors } from '../reducers/filtersReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import * as constants from '../constants';

export class ArticleTableFiltersContainer extends Component {
    render() {
        return <ArticleTableFilters {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        // catalogFilter: selectors.getFilter(state, constants.CATALOG_FILTER),
        language: appSelectors.getLanguage(state),
        lineFilter: selectors.getFilter(state, constants.LINE_FILTER),
        modelFilter: selectors.getFilter(state, constants.MODEL_FILTER),
        seriesFilter: selectors.getFilter(state, constants.SERIES_FILTER)
    };
}

export default connect(mapStateToProps)(ArticleTableFiltersContainer);
