import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function SubmitButton(props) {
    const { submitting, disabled } = props;

    return (
        <button
            className={cx('btn', 'btn-lg', 'btn-primary', 'btn-block', {
                loading: submitting
            })}
            type="submit"
            disabled={disabled}
        >
            {props.children}
        </button>
    );
}

SubmitButton.propTypes = {
    submitting: PropTypes.bool,
    disabled: PropTypes.bool
};
