import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { withI18n } from '@lingui/react';

import { client, getAssetURL } from '../../api';

registerPlugin(FilePondPluginFileValidateType);

export function LogoUploader({
    type,
    withBackground = false,
    height = null,
    helperText = null,
    i18n
}) {
    const [files, setFiles] = useState([]);
    const [timestamp, setTimestamp] = useState(new Date().getTime());

    let imgProps = {};

    if (height) {
        imgProps.height = height;
    }

    return (
        <>
            <h4 className="text-primary">Logo {type}</h4>
            <div
                className={withBackground ? 'bg-primary' : ''}
                style={{
                    padding: '0.75rem',
                    marginBottom: '0.1rem',
                    border: '1px solid lightgrey',
                    display: 'inline-block',
                    borderRadius: '5px'
                }}
            >
                <a
                    href={getAssetURL(`logo_${type}.png`)}
                    download={getAssetURL(`logo_${type}.png`)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={getAssetURL(`logo_${type}.png?t=${timestamp}`)}
                        alt={`App Logo ${type}`}
                        {...imgProps}
                    />
                </a>
            </div>
            {helperText && <div className="text-gray">{helperText}</div>}
            <FilePond
                name="file"
                files={files}
                allowMultiple={false}
                allowRevert={true}
                maxFiles={1}
                acceptedFileTypes={['image/png']}
                fileValidateTypeLabelExpectedTypesMap={{ 'image/png': '.png' }}
                server={{
                    url: `${client.defaults.baseURL}/upload/logo/${type}`,
                    headers: client.defaults.headers.common
                }}
                onupdatefiles={fileItems => {
                    setFiles(fileItems.map(fileItem => fileItem.file));
                }}
                onprocessfile={error => {
                    if (!error) {
                        setTimestamp(new Date().getTime());
                    }
                }}
                labelIdle={`<span class="filepond--label-action">${i18n._(
                    'click:to:upload'
                )}</span>`}
                labelFileProcessing={i18n._('uploading')}
                labelFileProcessingComplete={i18n._('upload:complete')}
                labelTapToCancel={i18n._('cancel')}
                labelFileTypeNotAllowed={i18n._('file:extension:invalid')}
                fileValidateTypeLabelExpectedTypes={`${i18n._('extensions:supported')}: {allTypes}`}
                labelTapToUndo={i18n._('upload:new:file')}
            />
        </>
    );
}

export default withI18n()(LogoUploader);
