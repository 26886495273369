import React from 'react';
import { getAssetURL } from './../../api';

const AppLogo = ({ height = 50 }) => (
    <img
        src={getAssetURL(`logo_full.png?t=${new Date().getTime()}`)}
        height={height}
        alt="Datapool logo"
    />
);

export default AppLogo;
