import React, { Component, Fragment } from 'react';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isArray from 'lodash/isArray';
// import upperFirst from 'lodash/upperFirst';

import Tooltip from '../utils/Tooltip';

class InputGroup extends Component {
    getError(error) {
        if (isArray(error)) {
            return this.props.i18n._(error[0]);
        }

        return this.props.i18n._(error);
    }

    hasError() {
        return Boolean(this.props.meta.touched && this.props.meta.error);
    }

    renderInputWithErrorWrapper() {
        const inputProps = { ...this.props, InputComponent: null };
        const {
            errorType,
            errorTooltipPosition,
            meta,
            InputComponent
        } = this.props;

        if (errorType === 'none') {
            return (
                <Fragment>
                    <InputComponent {...inputProps} />
                </Fragment>
            );
        }

        const error = this.getError(meta.error) || '';

        if (errorType === 'tooltip') {
            return (
                <Tooltip
                    content={error}
                    position={errorTooltipPosition}
                    visible={this.hasError()}
                    overlayClassName="rc-tooltip-error"
                >
                    <InputComponent {...inputProps} />
                </Tooltip>
            );
        }

        if (errorType === 'hint') {
            return (
                <Fragment>
                    <InputComponent {...inputProps} />
                    {this.hasError() && (
                        <p className="form-input-hint">{error}</p>
                    )}
                </Fragment>
            );
        }

        return (
            <Fragment>
                <InputComponent {...inputProps} />
            </Fragment>
        );
    }

    render() {
        const inputProps = { ...this.props, InputComponent: null };

        const {
            InputComponent,
            inputHorizontalClass,
            labelHorizontalClass,
            onlyInput,
            layout,
            label,
            placeholder,
            i18n,
            mandatory
        } = this.props;

        if (onlyInput) {
            return <InputComponent {...inputProps} />;
        }

        const groupClass = cx('form-group', {
            'has-error': this.hasError()
        });

        if (layout === 'inline') {
            return (
                <div className={groupClass}>
                    {this.renderInputWithErrorWrapper()}
                </div>
            );
        }

        const isHorizontal = layout === 'horizontal';

        const labelClass = cx('form-label', {
            [labelHorizontalClass]: isHorizontal
        });

        const inputWrapperClass = cx({
            [inputHorizontalClass]: isHorizontal
        });

        return (
            <div className={groupClass}>
                <label className={labelClass}>
                    {i18n._(label || placeholder)}
                    {mandatory && '*'}
                </label>
                <div className={inputWrapperClass}>
                    {this.renderInputWithErrorWrapper()}
                </div>
            </div>
        );
    }
}

const InputGroupWithI18n = withI18n()(InputGroup);

const InputGroupHoc = InputComponent => {
    return function InputGroupWrapper(props) {
        return (
            <InputGroupWithI18n {...props} InputComponent={InputComponent} />
        );
    };
};

InputGroup.propTypes = {
    errorType: PropTypes.oneOf(['none', 'tooltip', 'hint']),
    errorTooltipPosition: PropTypes.string,
    input: PropTypes.object,
    InputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        .isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    meta: PropTypes.object,
    onlyInput: PropTypes.bool,
    placeholder: PropTypes.string,
    inputHorizontalClass: PropTypes.string,
    labelHorizontalClass: PropTypes.string,
    layout: PropTypes.oneOf(['inline', 'horizontal', 'vertical'])
};

InputGroup.defaultProps = {
    errorType: 'tooltip',
    errorTooltipPosition: 'right',
    onlyInput: false,
    inputHorizontalClass: 'col-9 col-sm-12',
    labelHorizontalClass: 'col-3 col-sm-12',
    layout: 'inline'
};

export default InputGroupHoc;
