import { put, takeEvery, select, fork, cancel, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { actionTypes, setUser, updateUser } from '../actions/userActions';
import { selectors } from '../reducers/userReducer';
import { setToken, refresh } from '../api';
import history from '../history';
import { changeLanguage, changeDefaultTableView } from '../actions/appActions';
import { getShortCodeFromLocale } from '../intl-helpers';

let refreshTimeout = null;

function* authSuccessfullSaga({ payload }) {
    // console.warn(payload);
    setToken(payload.token);

    yield put(setUser(payload));
    yield put(changeLanguage(getShortCodeFromLocale(payload.utente.locale)));

    if (payload.utente.preferences && payload.utente.preferences.vista) {
        yield put(changeDefaultTableView(payload.utente.preferences.vista));
    }

    yield fork(queueRefreshTokenSaga);

    history.push('/app');
}

function* authLogoutSaga() {
    history.push('/login');

    yield cancel(refreshTimeout);
    // clearTimeout(refreshTimeout);
}

function* queueRefreshTokenSaga() {
    if (refreshTimeout) {
        yield cancel(refreshTimeout);
        // clearTimeout(refreshTimeout);
    }

    const expire = yield select(selectors.getTokenExpire);
    const now = Math.floor(Date.now() / 1000);
    const diff = expire - now;

    refreshTimeout = yield fork(doRefresh, expire, now, diff);

    // refreshTimeout = setTimeout(() => {
    //     console.warn('REFRESH');
    // }, 5000);
}

function* doRefresh(expire, now, diff) {
    // console.warn('Expire:', expire);
    // console.warn('Now:', now);
    // console.warn('Diff:', diff);

    // Eseguo il refresh 5 minuti prima della scadenza del token
    yield delay((diff - 300) * 1000);
    // yield delay(5000);

    const refreshToken = yield select(selectors.getRefreshToken);

    try {
        const res = yield call(refresh, refreshToken);

        // Aggiorno l'utente nello state
        yield put(updateUser(res.data));
        // Imposto il token nell'istanza di Axios
        setToken(res.data.token);

        // Rimetto in coda il refresh del token
        yield fork(queueRefreshTokenSaga);
    } catch (err) {
        // TODO: gestire errore
    }
}

export default [
    takeEvery(actionTypes.AUTH_SUCCESSFULL, authSuccessfullSaga),
    takeEvery(actionTypes.AUTH_LOGOUT, authLogoutSaga),
    takeEvery(actionTypes.QUEUE_REFRESH, queueRefreshTokenSaga)
];
