import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';
import { Trans, NumberFormat } from '@lingui/react';
import format from 'date-fns/format';
import Highlighter from 'react-highlight-words';
import get from 'lodash/get';

import ArticleDetailsClose from './ArticleDetailsClose';
import LimitText from './utils/LimitText';
import { getAssetURL } from '../api';
// import { UM_LABELS } from '../constants';
import noPhotoImg from '../images/no-photo.png';
import { getLocaleFromLanguageCode } from '../intl-helpers';

class RowDetailed extends Component {
    getValiditaPrezzo() {
        const { item } = this.props;

        if (item.prezzo_attuale && item.prezzo_attuale.data_inizio_validita_prezzo) {
            return format(item.prezzo_attuale.data_inizio_validita_prezzo.date, 'DD/MM/YYYY');
        }

        return null;
    }

    render() {
        const {
            bg,
            item,
            onCloseButtonClick,
            showDetails,
            q,
            language,
            selectable,
            onSelect
        } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        return (
            <Fragment>
                <td>
                    <div className="table-img-preview">
                        <Img
                            src={
                                item.immagine_prodotto
                                    ? getAssetURL(item.immagine_prodotto.url)
                                    : noPhotoImg
                            }
                            className="img-responsive m-a"
                            loader={<div className="rounded bg-secondary table-img-preview" />}
                            style={{
                                maxHeight: '145px'
                            }}
                        />
                    </div>
                </td>
                {selectable && (
                    <td>
                        <span className="form-group">
                            <label
                                className="form-checkbox form-checkbox-inline"
                                style={{ top: '-47px' }}
                                onClick={e => {
                                    e.stopPropagation();
                                    onSelect(item.id);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={item.in_collection}
                                    onClick={e => e.stopPropagation()}
                                    onChange={() => {}}
                                />
                                <i className="form-icon" />
                            </label>
                        </span>
                    </td>
                )}
                <td colSpan="6">
                    <div>
                        <span className="text-large">
                            <Highlighter searchWords={[q]} textToHighlight={item.codice_articolo} />
                        </span>
                        <br />
                        <span className="text-uppercase">
                            <Highlighter
                                searchWords={[q]}
                                textToHighlight={get(item, `descrizione_articolo[${locale}]`, '')}
                            />
                        </span>
                    </div>
                    <div className="columns" style={{ marginTop: 30 }}>
                        <div className="column col-3">
                            <span className="text-label text-uppercase">
                                <Trans id="brand" />
                            </span>
                            <br />
                            <LimitText limit={20} text={item.marca.name} />
                        </div>
                        <div className="column col-3">
                            <span className="text-label text-uppercase">
                                <Trans id="series" />
                            </span>
                            <br />
                            <LimitText
                                limit={23}
                                text={
                                    item.serie.length > 0
                                        ? item.serie.map(l => l.name[locale]).join(' / ')
                                        : '-'
                                }
                            />
                        </div>
                        <div className="column col-3">
                            <span className="text-label text-uppercase">
                                <Trans id="line" />
                            </span>
                            <br />
                            <LimitText
                                limit={23}
                                text={
                                    item.linee.length > 0
                                        ? item.linee.map(l => l.name[locale]).join(' / ')
                                        : '-'
                                }
                            />
                        </div>
                        <div className="column col-3">
                            <span className="text-label text-uppercase">
                                <Trans id="model" />
                            </span>
                            <br />
                            <LimitText
                                limit={23}
                                text={get(item, `modello.name[${locale}]`, '-')}
                            />
                        </div>
                    </div>
                </td>
                <td>
                    <div className="container">
                        <div className="table-cell-grid">
                            <div className="text-center">
                                <span className="text-label text-uppercase">
                                    <Trans id="um" />
                                </span>
                                <br />
                                <span
                                    className="tooltip"
                                    data-tooltip={
                                        item.unita_ordine.name && item.unita_ordine.name[locale]
                                            ? item.unita_ordine.name[locale]
                                            : ''
                                    }
                                >
                                    {item.unita_ordine.code}
                                </span>
                            </div>
                        </div>
                        <div className="table-cell-grid">
                            <div className="text-center pt-2">
                                <span className="text-label text-uppercase">
                                    <Trans id="conf" />
                                </span>
                                <br />
                                {item.quantita_contenuta ? item.quantita_contenuta : '-'}
                            </div>
                        </div>
                    </div>
                </td>
                <td className="text-right">
                    {item.prezzo !== null ? (
                        <Fragment>
                            <span className="table-price-lg">
                                €{' '}
                                <NumberFormat
                                    value={item.prezzo}
                                    format={{ minimumFractionDigits: 4 }}
                                />
                            </span>
                            <br />
                            <span className="table-date-sm">
                                {this.getValiditaPrezzo() !== null && (
                                    <Fragment>
                                        <Trans id="from" /> {this.getValiditaPrezzo()}
                                    </Fragment>
                                )}
                            </span>
                        </Fragment>
                    ) : (
                        '-'
                    )}
                    {showDetails && <ArticleDetailsClose bg={bg} onClick={onCloseButtonClick} />}
                </td>
            </Fragment>
        );
    }
}

RowDetailed.propTypes = {
    bg: PropTypes.string,
    index: PropTypes.number,
    onCloseButtonClick: PropTypes.func,
    showDetails: PropTypes.bool
};

export default RowDetailed;
