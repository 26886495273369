import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import filesize from 'filesize';

function getFileName(fileName) {
    if (fileName !== null) {
        return fileName.split(/(\\|\/)/g).pop();
    }
}

class ArticleDataGalleryModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: props.startingIndex
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startingIndex !== this.props.startingIndex) {
            this.setState({
                currentIndex: this.props.startingIndex
            });
        }
    }

    getCurrentItem() {
        return this.props.items[this.state.currentIndex];
    }

    hasNext() {
        return this.state.currentIndex < this.props.items.length - 1;
    }

    hasPrevious() {
        return this.state.currentIndex - 1 >= 0;
    }

    nextItem = () => {
        this.setState(prevState => {
            return {
                currentIndex: prevState.currentIndex + 1
            };
        });
    };

    previousItem = () => {
        this.setState(prevState => {
            return {
                currentIndex: prevState.currentIndex - 1
            };
        });
    };

    render() {
        const { onClose } = this.props;

        const currentItem = this.getCurrentItem();
        const hasNext = this.hasNext();
        const hasPrevious = this.hasPrevious();

        return (
            <Fragment>
                <div className="modal-header">
                    <span
                        className="btn btn-clear float-right"
                        aria-label="Close"
                        onClick={onClose}
                    />
                    <div className="modal-title h4">{currentItem.label}</div>
                </div>
                <div className="divider" />
                <div className="modal-body text-center">
                    <div
                        style={{
                            position: 'absolute',
                            top: '40%'
                        }}
                    >
                        <i
                            onClick={hasPrevious ? this.previousItem : () => {}}
                            className={cx('icon icon-4x icon-arrow-left', {
                                'c-hand': hasPrevious,
                                'text-gray': !hasPrevious
                            })}
                        />
                    </div>
                    <img
                        className="img-responsive"
                        style={{ margin: '0 auto', maxHeight: '60vh' }}
                        src={currentItem.src}
                        alt={currentItem.label}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '40%',
                            right: 0
                        }}
                    >
                        <i
                            onClick={hasNext ? this.nextItem : () => {}}
                            className={cx('icon icon-4x icon-arrow-right', {
                                'c-hand': hasNext,
                                'text-gray': !hasNext
                            })}
                        />
                    </div>
                    <div className="my-2" />
                    <div className="my-2 text-center">
                        <strong>Nome del file:</strong> {getFileName(currentItem.filename)}
                        {currentItem.size && (
                            <Fragment>
                                {' '}
                                - <strong>Kb:</strong>{' '}
                                {filesize(currentItem.size, {
                                    separator: ',',
                                    base: 10
                                })}
                            </Fragment>
                        )}
                    </div>
                    <a
                        className="btn btn-lg btn-primary btn-rounded"
                        href={currentItem.src}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="icon icon-download" /> DOWNLOAD
                    </a>
                </div>
                <div className="modal-footer" />
            </Fragment>
        );
    }
}

ArticleDataGalleryModal.propTypes = {
    items: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    startingIndex: PropTypes.number
};

ArticleDataGalleryModal.defaultProps = {
    items: []
};

export default ArticleDataGalleryModal;
