import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans, i18nMark } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';
// import MakeAsyncFunction from 'react-redux-promise-listener';

// import { promiseListener } from '../store/configureStore';
import InputField from './form/InputField';
import { createCollection, updateCollection } from '../api';
// import {
//     UPDATE_COLLECTION,
//     UPDATE_COLLECTION_FAIL,
//     UPDATE_COLLECTION_SUCCESS
// } from '../actions/collectionsActions';

const validate = data => {
    let errors = {};

    if (!data.descrizione) {
        errors.descrizione = i18nMark('validation:error:mandatory:field');
    }

    return errors;
};

export class EditCollectionModal extends Component {
    componentDidMount() {
        setImmediate(() => {
            this.firstInput.focus();
        });
    }

    onSubmit = async data => {
        const { collection, i18n, user } = this.props;

        let payload = data;

        let res = null;

        const isUpdating = collection.id !== null;

        const xhr = isUpdating
            ? updateCollection(user.id, collection.id, payload)
            : createCollection(user.id, payload);

        try {
            res = await xhr;
        } catch (err) {
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                // console.log(err.response.data);
                // console.log(err.response.status);
                if (err.response.status === 412) {
                    errorMessage = i18n._('fill:required:fields');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage
            };
        }

        if (isUpdating) {
            this.props.updateCollectionSuccess(res);
        } else {
            this.props.fetchCollections();
        }

        const msg = isUpdating ? i18n._('collection:updated') : i18n._('collection:created');

        toast(msg, {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS
        });

        this.props.onClose();
    };

    render() {
        const { onClose, collection } = this.props;

        const isCreating = collection.id === null;

        return (
            <div className="modal active" id="user-details-modal-id">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container" style={{ maxWidth: '800px' }}>
                    <div className="modal-header">
                        <div className="modal-title text-center h5">
                            {isCreating ? (
                                <Trans id="Create new collection" />
                            ) : (
                                <Trans id="Edit collection" />
                            )}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body" id="user-details-modal">
                        <div className="content">
                            <div className="container">
                                <div className="columns">
                                    <div className="column col-12">
                                        <Form
                                            onSubmit={this.onSubmit}
                                            validate={validate}
                                            initialValues={this.props.collection.toJS()}
                                            render={({
                                                handleSubmit,
                                                pristine,
                                                hasValidationErrors,
                                                submitError,
                                                submitting,
                                                errors,
                                                touched,
                                                submitSucceeded,
                                                form,
                                                ...rest
                                            }) => {
                                                this.form = form;

                                                return (
                                                    <form
                                                        onSubmit={handleSubmit}
                                                        className="form-horizontal"
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <div className="column col-12">
                                                            <Field
                                                                name="descrizione"
                                                                component={InputField}
                                                                placeholder={i18nMark(
                                                                    'description'
                                                                )}
                                                                className="form-input input-md my-2"
                                                                // layout="horizontal"
                                                                mandatory={true}
                                                                onlyInput={true}
                                                                forwardRef={ref =>
                                                                    (this.firstInput = ref)
                                                                }
                                                            />
                                                            <Field
                                                                name="codice"
                                                                component={InputField}
                                                                placeholder={i18nMark('code')}
                                                                className="form-input input-md my-2"
                                                                // layout="horizontal"
                                                                onlyInput={true}
                                                            />
                                                            <Field
                                                                name="note"
                                                                component={InputField}
                                                                placeholder={i18nMark('note')}
                                                                className="form-input input-md my-2"
                                                                //layout="horizontal"
                                                                onlyInput={true}
                                                            />
                                                        </div>
                                                        <div className="column col-12 text-right">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={this.props.onConfirm}
                                                                disabled={submitting}
                                                            >
                                                                {isCreating ? (
                                                                    <Trans id="confirm:create" />
                                                                ) : (
                                                                    <Trans id="confirm:update" />
                                                                )}
                                                            </button>{' '}
                                                            <span
                                                                className="btn"
                                                                onClick={this.props.onClose}
                                                                disabled={submitting}
                                                            >
                                                                <Trans id="cancel" />
                                                            </span>
                                                        </div>
                                                    </form>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditCollectionModal.propTypes = {
    collection: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object
};

export default withI18n()(EditCollectionModal);
