import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NumberFormat } from '@lingui/react';
import Highlighter from 'react-highlight-words';

import LimitText from './utils/LimitText';
import ArticleDetailsClose from './ArticleDetailsClose';
import { getLocaleFromLanguageCode } from '../intl-helpers';
// import { UM_LABELS } from '../constants';

class RowCompact extends Component {
    render() {
        const {
            bg,
            item,
            onCloseButtonClick,
            onSelect,
            showDetails,
            language,
            q,
            selectable
        } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        return (
            <Fragment>
                <td style={{ width: '160px' }}>
                    <LimitText limit={15} text={item.marca.name} />
                </td>
                <td>
                    <LimitText limit={33} text={item.codice_articolo} highlight={q} />
                </td>
                {selectable && (
                    <td>
                        <span className="form-group">
                            <label
                                className="form-checkbox form-checkbox-inline"
                                style={{ top: 0, paddingRight: 0 }}
                                onClick={e => {
                                    e.stopPropagation();
                                    onSelect(item.id);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={item.in_collection}
                                    onClick={e => e.stopPropagation()}
                                    onChange={() => {}}
                                />
                                <i className="form-icon" />
                            </label>
                        </span>
                    </td>
                )}
                <td>
                    <Highlighter
                        searchWords={[q]}
                        textToHighlight={
                            item.descrizione_articolo && item.descrizione_articolo[locale]
                                ? item.descrizione_articolo[locale]
                                : ''
                        }
                    />
                </td>
                <td>
                    <span
                        className="tooltip"
                        data-tooltip={
                            /*UM_LABELS[item.id_um]*/
                            item.unita_ordine.name && item.unita_ordine.name[locale]
                                ? item.unita_ordine.name[locale]
                                : ''
                        }
                    >
                        {item.unita_ordine.code}
                    </span>
                </td>
                <td className="text-right">
                    {item.prezzo !== null ? (
                        <Fragment>
                            €{' '}
                            <NumberFormat
                                value={item.prezzo}
                                format={{ minimumFractionDigits: 4 }}
                            />
                        </Fragment>
                    ) : (
                        '-'
                    )}
                    {showDetails && <ArticleDetailsClose bg={bg} onClick={onCloseButtonClick} />}
                </td>
            </Fragment>
        );
    }
}

RowCompact.propTypes = {
    bg: PropTypes.string,
    index: PropTypes.number,
    onCloseButtonClick: PropTypes.func,
    onSelect: PropTypes.func,
    showDetails: PropTypes.bool
};

export default RowCompact;
