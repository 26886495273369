export const BOOTSTRAP_APP = 'BOOTSTRAP_APP';
export const BOOTSTRAP_APP_START = 'BOOTSTRAP_APP_START';
export const BOOTSTRAP_APP_SUCCESS = 'BOOTSTRAP_APP_SUCCESS';
export const BOOTSTRAP_APP_FAIL = 'BOOTSTRAP_APP_FAIL';
export const HIDE_LOADING_SPLASH_SCREEN = 'HIDE_LOADING_SPLASH_SCREEN';
// export const SET_PRODUCT_INFO_MAP = 'SET_PRODUCT_INFO_MAP';
export const RESTORE_BOOTSTRAPPED_STATE = 'RESTORE_BOOTSTRAPPED_STATE';
export const SET_FIXED_BRAND = 'SET_FIXED_BRAND';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_PRODUCT_PROPERTIES = 'SET_PRODUCT_PROPERTIES';
export const SET_APP_NAME = 'SET_APP_NAME';
export const CHANGE_DEFAULT_TABLE_VIEW = 'CHANGE_DEFAULT_TABLE_VIEW';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const ADD_USER_GROUP = 'ADD_USER_GROUP';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';

export function bootstrapApp() {
    return {
        type: BOOTSTRAP_APP
    };
}

export function bootstrapAppStart() {
    return {
        type: BOOTSTRAP_APP_START
    };
}

export function bootstrapAppSuccess() {
    return {
        type: BOOTSTRAP_APP_SUCCESS
    };
}

export function bootstrapAppFail(errorMessage) {
    return {
        type: BOOTSTRAP_APP_FAIL,
        payload: errorMessage
        // TODO: portare errore nel payload
    };
}

// export function setProductInfoMap(data) {
//     return {
//         type: SET_PRODUCT_INFO_MAP,
//         payload: data
//     };
// }

export function hideLoadingSplashScreen() {
    return {
        type: HIDE_LOADING_SPLASH_SCREEN
    };
}

export function restoreBootstrappedState(options = {}) {
    return {
        type: RESTORE_BOOTSTRAPPED_STATE,
        payload: options
    };
}

export function setFixedBrand(value) {
    return {
        type: SET_FIXED_BRAND,
        payload: value
    };
}

export function changeLanguage(lang) {
    return {
        type: CHANGE_LANGUAGE,
        payload: lang
    };
}

export function setProductProperties(data) {
    return {
        type: SET_PRODUCT_PROPERTIES,
        payload: data
    };
}

export function setAppName(name) {
    return {
        type: SET_APP_NAME,
        payload: name
    };
}

export function changeDefaultTableView(type) {
    return {
        type: CHANGE_DEFAULT_TABLE_VIEW,
        payload: type
    };
}

export function setUserGroups(groups) {
    return {
        type: SET_USER_GROUPS,
        payload: groups
    };
}

export function addUserGroup(gropuId, data) {
    return {
        type: ADD_USER_GROUP,
        payload: {
            id: gropuId,
            data
        }
    };
}

export function updateUserGroup(gropuId, data) {
    return {
        type: UPDATE_USER_GROUP,
        payload: {
            id: gropuId,
            data
        }
    };
}

export const actionTypes = {
    BOOTSTRAP_APP,
    BOOTSTRAP_APP_START,
    BOOTSTRAP_APP_SUCCESS,
    BOOTSTRAP_APP_FAIL,
    // SET_PRODUCT_INFO_MAP,
    HIDE_LOADING_SPLASH_SCREEN,
    RESTORE_BOOTSTRAPPED_STATE,
    SET_FIXED_BRAND,
    CHANGE_LANGUAGE,
    SET_PRODUCT_PROPERTIES,
    SET_APP_NAME,
    CHANGE_DEFAULT_TABLE_VIEW,
    SET_USER_GROUPS,
    ADD_USER_GROUP,
    UPDATE_USER_GROUP
};

export const actions = {
    bootstrapApp,
    bootstrapAppStart,
    bootstrapAppSuccess,
    bootstrapAppFail,
    // setProductInfoMap,
    hideLoadingSplashScreen,
    restoreBootstrappedState,
    setFixedBrand,
    changeLanguage,
    setProductProperties,
    setAppName,
    changeDefaultTableView,
    setUserGroups,
    addUserGroup,
    updateUserGroup
};
