import { actionTypes } from '../actions/filtersActions';
import { Record, List } from 'immutable';
import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import without from 'lodash/without';

import { selectors as sectorsSelectors } from './sectorsReducer';
import { selectors as productClassesSelectors } from './productClassesReducer';
import { selectors as itemsSelectors } from './itemsReducer';
import { selectors as appSelectors } from './appReducer';
// import { getHashFromPayload } from '../sagas/itemsSaga';
import { OptionRecord, SelectFilterRecord } from './helpers';
import * as constants from '../constants';
import { SET_FIXED_BRAND } from '../actions/appActions';
import { getLocaleFromLanguageCode } from '../intl-helpers';

const SimpleFilterRecord = Record({
    enabled: false,
    value: true
});

const StateRecord = Record({
    [constants.BRAND_FILTER]: SelectFilterRecord({ enabled: true }),
    //[constants.CATALOG_FILTER]: SelectFilterRecord(),
    [constants.FULL_TEXT_FILTER]: SimpleFilterRecord({
        enabled: true,
        value: ''
    }),
    [constants.LINE_FILTER]: SelectFilterRecord(),
    [constants.OPTIONALS_FILTER]: SimpleFilterRecord(),
    [constants.MODEL_FILTER]: SelectFilterRecord(),
    [constants.SERIES_FILTER]: SelectFilterRecord(),
    [constants.REPLACEMENTS_FILTER]: SimpleFilterRecord(),
    // [constants.GOING_OUT_OF_PRODUCTION_FILTER]: SimpleFilterRecord(),
    // [constants.OUT_OF_PRODUCTION_FILTER]: SimpleFilterRecord(),
    [constants.STATUS_FILTER]: SelectFilterRecord()
});

export const DEFAULT_STATE = StateRecord();

function createOptionObject(key, o) {
    switch (key) {
        case constants.BRAND_FILTER:
        case constants.STATUS_FILTER:
        case constants.LINE_FILTER:
        case constants.MODEL_FILTER:
        case constants.SERIES_FILTER:
            return {
                label: o.description,
                value: o.id,
                code: o.code
            };
        default:
            return o;
    }
}

function setFilterValue(state, action) {
    const {
        payload: { key, value }
    } = action;

    let newValue = value;

    if (isObject(value)) {
        newValue = OptionRecord(value);
    }

    return state.setIn([key, 'value'], newValue);
}

function setFilterOptions(state, action) {
    const {
        payload: { key, options }
    } = action;

    return state.setIn(
        [key, 'options'],
        List(options.map(o => OptionRecord(createOptionObject(key, o))))
    );
}

function enableFilters(state, action) {
    let result = state;

    action.payload.forEach(key => {
        result = result.setIn([key, 'enabled'], true);
    });

    return result;
}

function disableFilters(state, action) {
    let result = state;

    action.payload.forEach(key => {
        result = result.setIn([key, 'enabled'], false);
    });

    return result;
}

function resetFilters(state, action) {
    const res = action.payload.reduce((newState, key) => {
        const oldRecord = newState.get(key);

        const newRecord = oldRecord.clear().set('enabled', oldRecord.enabled);

        return newState.set(key, newRecord);
    }, state);

    return res;
}

function setBrandFilter(state, action) {
    return state.setIn([constants.BRAND_FILTER, 'value'], action.payload);
}

const handlers = {
    [actionTypes.SET_FILTER_VALUE]: setFilterValue,
    [actionTypes.SET_FILTER_OPTIONS]: setFilterOptions,
    [actionTypes.ENABLE_FILTERS]: enableFilters,
    [actionTypes.DISABLE_FILTERS]: disableFilters,
    [actionTypes.RESET_FILTERS]: resetFilters,
    [SET_FIXED_BRAND]: setBrandFilter
};

export default function filtersReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

function getSelectsValues(state) {
    let values = {};

    const keys = [
        constants.BRAND_FILTER,
        // constants.CATALOG_FILTER,
        constants.LINE_FILTER,
        constants.MODEL_FILTER,
        constants.SERIES_FILTER,
        constants.STATUS_FILTER
    ];

    keys.forEach(key => {
        values[key] = state.filters[key].value ? state.filters[key].value.value : null;
    });

    return values;
}

function formatAttributeValue(value, type) {
    switch (type) {
        case constants.ATTRIBUTE_TYPE_NUMERIC:
        default:
            return value;
        case constants.ATTRIBUTE_TYPE_LOGIC:
            return value === 'yes';
        case constants.ATTRIBUTE_TYPE_ALPHANUMERIC:
            return value.toArray();
        case constants.ATTRIBUTE_TYPE_RANGE:
            return value.toArray().map(v => {
                const [start, end] = v.split('::');
                return [parseFloat(start), parseFloat(end)];
            });
    }
}

function getApiPayload(state, overrides = {}) {
    let payload = {
        per_page: itemsSelectors.getPerPage(state),
        page: itemsSelectors.getCurrentPage(state) + 1
    };

    const languageCode = appSelectors.getLanguage(state);

    payload.locale = getLocaleFromLanguageCode(languageCode);

    const brandFilter = selectors.getFilter(state, constants.BRAND_FILTER);

    if (brandFilter.enabled && brandFilter.value) {
        payload.marca = brandFilter.value.value;
    }

    const statusFilter = selectors.getFilter(state, constants.STATUS_FILTER);

    if (statusFilter.enabled && statusFilter.value) {
        payload.stato = statusFilter.value.value;
    }

    const optionals = selectors.getFilter(state, constants.OPTIONALS_FILTER);

    if (optionals.enabled) {
        payload.include_accessori = optionals.value;
    }

    const replacementsFlag = selectors.getFilter(state, constants.REPLACEMENTS_FILTER);

    if (replacementsFlag.enabled) {
        payload.include_ricambi = replacementsFlag.value;
    }

    payload.product_tree = sectorsSelectors.getFilterValue(state);

    const node = sectorsSelectors.getSelectedTreeItemRecord(state);

    if (node) {
        node.filterParams.forEach((v, k) => {
            payload[k] = v;
        });
    }

    /*const idrolabClassification = sectorsSelectors.getIdrolabClassification(state);

    if (idrolabClassification !== null) {
        payload[idrolabClassification.type] = idrolabClassification.id;
    }*/

    const fullTextFilter = selectors.getFilter(state, constants.FULL_TEXT_FILTER);

    if (fullTextFilter.enabled && fullTextFilter.value !== '') {
        payload.q = fullTextFilter.value;
    }

    const lineFilter = selectors.getFilter(state, constants.LINE_FILTER);

    if (lineFilter.enabled && lineFilter.value) {
        payload.linea = lineFilter.value.value;
    }

    const modelFilter = selectors.getFilter(state, constants.MODEL_FILTER);

    if (modelFilter.enabled && modelFilter.value) {
        payload.modello = modelFilter.value.value;
    }

    const seriesFilter = selectors.getFilter(state, constants.SERIES_FILTER);

    if (seriesFilter.enabled && seriesFilter.value) {
        payload.serie = seriesFilter.value.value;
    }

    // const catalogFilter = selectors.getFilter(state, constants.CATALOG_FILTER);

    // if (catalogFilter.enabled && catalogFilter.value) {
    //     payload.listino = catalogFilter.value.value;
    // }

    const productClassFilter = productClassesSelectors.getFilter(state);

    if (productClassFilter.enabled && productClassFilter.value) {
        payload.etim_class = productClassFilter.value.value;
    }

    const activeFilters = productClassesSelectors.getActiveFilters(state);
    const attributes = productClassesSelectors.getProductClassAttributes(state);

    const features = activeFilters.map((value, key) => {
        const attribute = attributes.get(key);

        return {
            id: key,
            type: attribute.type,
            value: formatAttributeValue(value, attribute.type)
        };
    });

    if (features.size > 0) {
        payload.etim_features = features.toJS();
    }

    const sorting = itemsSelectors.getSorting(state);

    if (sorting.sortBy) {
        payload.sort = sorting.sortBy;
        payload.sort_direction = sorting.sortDirection.toUpperCase();
    }

    let result = Object.assign({}, payload, overrides);

    // if (currentHash !== previousHash) {
    //     result.page = 1;
    // }

    // console.log(result);

    return result;
}

export const nonFiltersKeys = [
    'per_page',
    'page',
    'locale',
    'product_tree',
    'include_accessori',
    'include_ricambi'
];

const orderKeys = ['sort', 'sort_direction'];

export const selectors = {
    getState(state) {
        return state.filters;
    },
    getFilter(state, key) {
        return state.filters[key];
    },
    getFilters(state) {
        const selects = getSelectsValues(state);

        return {
            ...selects,
            [constants.OPTIONALS_FILTER]: state.filters[constants.OPTIONALS_FILTER].value,
            [constants.REPLACEMENTS_FILTER]: state.filters[constants.REPLACEMENTS_FILTER].value,
            [constants.FULL_TEXT_FILTER]: state.filters[constants.FULL_TEXT_FILTER].value
            // [constants.OUT_OF_PRODUCTION_FILTER]:
            //     state.filters[constants.OUT_OF_PRODUCTION_FILTER].value,
            // [constants.GOING_OUT_OF_PRODUCTION_FILTER]:
            //     state.filters[constants.GOING_OUT_OF_PRODUCTION_FILTER].value
        };
    },
    // TODO: questo è giusto che stia qui?
    getApiPayload,
    isAnyFilterActive: createSelector(
        [getApiPayload, appSelectors.getIsBrandFixed],
        (payload, isBrandFixed) => {
            const filters = Object.keys(payload);

            let skipKeys = [];

            // if (isBrandFixed) {
            //     skipKeys = ['marca'];
            // }

            return without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys).length > 0;
        }
    ),
    isAnyFilterActiveOrOrderer: createSelector([getApiPayload], payload => {
        const filters = Object.keys(payload);

        return without(filters, ...nonFiltersKeys).length > 0;
    })
};

// marca: <uuid>
// stato: <uuid>
// serie: <uuid>
// linea: <uuid>
// q: <string>
// settore: <uuid>
// macrofamiglia: <uuid>
// famiglia: <uuid>
// include_accessori: true|false
// include_ricambi: true|false
// etim_class: <uuid>
// classification: etim|idrolab
// etim_features:
//     - id: <uuid>
//       type: alphanumeric
//       value: [<uuid>, <uuid>, ...]
//     - id: <uuid>
//       type: logic
//       value: true|false
//     - id: <uuid>
//       type: numeric
//       value: [<min>, <max>]
//     - id: <uuid>
//       type: range
//       value:
//         - [<float>, <float>]
//         - [<float>, <float>]
//         - ...
//     - ...
// page: <num> (solo per /articoli, verrà ignorata dai filtri)
// per_page: <num> (solo per /articoli, verrà ignorata dai filtri)
// sort: <string> (solo per /articoli, verrà ignorata dai filtri)
//     valori ammessi:
//         prezzo
//         codice_articolo
//         descrizione_articolo
//         modello
//         marca

// sort_direction: ASC|DESC (solo per /articoli, verrà ignorata dai filtri)
