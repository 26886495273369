import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteTree from 'react-infinite-tree';
import cx from 'classnames';
import { hash } from 'immutable';

import Loader from './utils/Loader';
import sortBy from 'lodash/sortBy';
import { getLocaleFromLanguageCode } from '../intl-helpers';

function sortTree(leafs, locale) {
    return sortBy(leafs, leaf => {
        if (leaf.children && leaf.children.length > 0) {
            leaf.children = sortTree(leaf.children, locale);
        }

        // FIXME: tradurre
        return leaf.name ? leaf.name[locale] : 'Non classificato';
    });
}

class SectorsTree extends Component {
    tree = null;

    componentDidMount() {
        this.loadTreeFromProps();
    }

    componentDidUpdate(prevProps) {
        const hasHashChanged = hash(prevProps.data) !== hash(this.props.data);
        const hasFetchFinished = prevProps.isFetching === true && this.props.isFetching === false;
        const hasLanguageChange = prevProps.language !== this.props.language;

        // console.warn('hash', hasHashChanged);
        // console.warn('fetch', hasFetchFinished);

        // console.warn(prevProps.selectedItem, this.props.selectedItem);

        // Se é cambiata la struttura dell'albero (hash) o sono stati rifetchati i dati ricarico l'albero e mantengo eventuale nodo selezionato
        if (hasHashChanged || hasFetchFinished || hasLanguageChange) {
            this.loadTreeFromProps();
            this.mantainSelectedLeaf();
            // altrimenti se è cambiato l'oggetto selezionato verifico se necessario chiudere altri nodi e mantengo comunque il nodo selezionato
        } else if (prevProps.selectedItem !== this.props.selectedItem) {
            // console.warn('!==');
            this.checkOpenBranches();
            this.mantainSelectedLeaf();
        }
    }

    checkOpenBranches() {
        if (this.tree) {
            const selectedNode = this.tree.getNodeById(this.props.selectedItem);

            if (selectedNode) {
                // Se il nodo selezionato non ha figli chiudo tutti gli altri eventuali nodi aperti, da richiesta del committente
                if (selectedNode.hasChildren() === false) {
                    const openNodes = this.tree.getOpenNodes();

                    // reverse per cercare di chiudere prima i nodi più profondi dell'albero
                    openNodes.reverse().forEach(n => {
                        // verifico se il nodo esiste ancora nell'albero perchè chiudendo un nodo
                        // potrei chiudere anche uno dei figli che era aperto e darebbe errore
                        if (this.tree.nodes.indexOf(n) !== -1) {
                            this.tree.closeNode(n);
                        }
                    });
                }
            }
        }
    }

    loadTreeFromProps() {
        if (this.tree) {
            const sortedTree = sortTree(this.props.data.toJS(), this.getLocale());
            this.tree.loadData(sortedTree);
        }
    }

    mantainSelectedLeaf() {
        // console.warn(this.props);
        // console.warn(this.tree);

        if (this.tree) {
            // Ottengo il nodo selezionato da prop e quello nello stato interno dell'albero
            const node = this.tree.getNodeById(this.props.selectedItem);
            const selectedNode = this.tree.getSelectedNode();

            // console.warn(node === selectedNode);

            // Se sono differenti trovo i parent del nodo selezionato e li apro, scrollando sul nodo selezionato
            if (node && node !== selectedNode) {
                const parent = node.getParent();

                // let nodeToScroll = null;

                if (parent.id !== null) {
                    // nodeToScroll = parent;
                    this.tree.openNode(parent);

                    const grandPa = parent.getParent();

                    if (grandPa.id !== null) {
                        this.tree.openNode(grandPa);
                        // nodeToScroll = grandPa;
                    }

                    this.tree.scrollToNode(selectedNode);
                }
            }
        }
    }

    handleItemClick(node) {
        this.tree.selectNode(node);
        this.props.onItemClick(node.id, node.state.depth);
    }

    getLocale() {
        return getLocaleFromLanguageCode(this.props.language);
    }

    getItemLabel(name, code) {
        if (name && name[this.getLocale()]) {
            return name[this.getLocale()];
        }

        if (code) {
            return `[${code}]`;
        }

        return '[n.d.]';
    }

    render() {
        if (this.props.isFetching) {
            return <Loader />;
        }

        return (
            <InfiniteTree
                ref={node => (this.tree = node ? node.tree : null)}
                autoOpen={false}
                selectable={true}
                height={this.props.sidebarHeight - 255}
                width="100%"
                rowHeight={24}
                // onSelectNode={node => console.warn(node)}
                // onClick={event => {
                // console.warn(event);
                // const target = event.target;
                // console.log('click:', target);
                // }}
                // onSelectNode={node => console.warn(node)}
                rowRenderer={({ tree, node }) => {
                    const {
                        id,
                        name,
                        code,
                        /*selected, count,*/
                        state /*children,  props = {}*/
                    } = node;
                    const { depth, open /*, path, total*/ } = state;
                    const { options } = tree;
                    const more = node.hasChildren();
                    // const isClass = depth > 0;

                    const isSelected = this.props.selectedItem === id;

                    // console.warn(name, code, id);

                    return (
                        <div
                            className={cx('infinite-tree-item', {
                                'bg-secondary': isSelected
                            })}
                            data-id={id}
                        >
                            <div className="infinite-tree-node" style={{ marginLeft: depth * 18 }}>
                                {more && open && (
                                    <span
                                        className={cx(options.togglerClass, 'c-hand')}
                                        onClick={() => tree.closeNode(node)}
                                    >
                                        <i className="icon icon-small icon-arrow-down mr-1 text-primary" />
                                    </span>
                                )}
                                {more && !open && (
                                    <span
                                        className={cx(
                                            options.togglerClass,
                                            'c-hand',
                                            'infinite-tree-closed'
                                        )}
                                        onClick={() => tree.openNode(node)}
                                    >
                                        <i className="icon icon-small icon-plus mr-1 text-primary" />
                                    </span>
                                )}
                                {!more && (
                                    <span
                                        className={cx(options.togglerClass, 'infinite-tree-closed')}
                                    >
                                        <i className="icon icon-small icon-minus mr-1 text-primary" />
                                    </span>
                                )}
                                <span
                                    className={cx(
                                        'infinite-tree-title',
                                        'c-hand',
                                        'tooltip',
                                        'tooltip-wide',
                                        {
                                            'tooltip-bottom': state.path === '.0'
                                        }
                                    )}
                                    data-tooltip={this.getItemLabel(name, code)}
                                    onClick={() => this.handleItemClick(node)}
                                >
                                    <span
                                        className={
                                            isSelected
                                                ? 'infinite-tree-leaf text-bold'
                                                : 'infinite-tree-leaf'
                                        }
                                    >
                                        {this.getItemLabel(name, code)}
                                    </span>
                                    {/*more && (
                                        <span className="text-gray">
                                            ({count})
                                        </span>
                                    )*/}
                                </span>
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}

SectorsTree.propTypes = {
    data: PropTypes.object,
    selectedItem: PropTypes.string,
    language: PropTypes.string,
    // onSelectClass: PropTypes.func,
    // onSelectGroup: PropTypes.func,
    onItemClick: PropTypes.func,
    sidebarHeight: PropTypes.number
};

SectorsTree.defaultProps = {
    data: []
};

export default SectorsTree;
