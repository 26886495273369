import React, { Component } from 'react';
import Resizable from 're-resizable';
import ContainerDimensions from 'react-container-dimensions';
import qs from 'query-string';

import Sidebar from '../containers/Sidebar';
import ArticleTable from '../containers/ArticleTable';
import { ArticleDetailsModal } from './ArticleDetailsModal';

function getMinSidebarWidth(windowWidth) {
    const w = Math.floor(windowWidth / 5);

    if (w > 250) {
        return w;
    }

    return 250;
}

function getMaxSidebarWidth(windowWith) {
    const w = Math.floor(windowWith / 3);

    if (w > 0) {
        return w;
    }

    return 400;
}

export default class DataPool extends Component {
    state = {
        showArticleModal: false
    };

    componentDidMount() {
        const search = qs.parse(window.location.search);

        if (search.showArticle) {
            this.setState({
                showArticleModal: search.showArticle
            });

            // TODO: replace url per eliminare querystring?
        }
    }

    render() {
        const { width } = this.props;

        return (
            <div className="container" style={{ display: 'flex' }}>
                <Resizable
                    defaultSize={{
                        width: getMinSidebarWidth(width),
                        height: 'auto'
                    }}
                    minWidth={getMinSidebarWidth(width)}
                    maxWidth={getMaxSidebarWidth(width)}
                    enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false
                    }}
                >
                    <div className="sidebar-area">
                        <ContainerDimensions>
                            {({ height }) => <Sidebar height={height} />}
                        </ContainerDimensions>
                    </div>
                </Resizable>
                <div className="content-area mt-1">
                    <ArticleTable />
                </div>
                {this.state.showArticleModal && (
                    <ArticleDetailsModal
                        article={{ id: this.state.showArticleModal }}
                        onClose={() => this.setState({ showArticleModal: false })}
                    />
                )}
            </div>
        );
    }
}
