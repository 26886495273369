import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import VirtualList from 'react-tiny-virtual-list';
import { Trans, withI18n } from '@lingui/react';
import fuzzaldrin from 'fuzzaldrin-plus';
import cx from 'classnames';
import sortBy from 'lodash/sortBy';
import matchSorter from 'match-sorter';

// import { matchAndSort /*, profiler*/ } from '../helpers';

/*const fuzzyFilter = (items, input) => {
    return fuzzaldrin.filter(items, input, { key: 'localizedLabel' });
};*/

class DropdownList extends Component {
    renderIcon(selectedItem, isOpen, clearSelection, getToggleButtonProps) {
        const { disabled, isLoading, clearable, showClearAndSelectBtn } = this.props;

        if (isLoading) {
            return <i className="form-icon loading" />;
        }

        const res = [];

        if (selectedItem && clearable) {
            res.push(
                <i
                    className="form-icon icon icon-cross c-hand"
                    style={{ right: '1rem' }}
                    disabled={disabled}
                    onClick={() => (disabled ? false : clearSelection())}
                    key="clear"
                />
            );
        }

        if (showClearAndSelectBtn) {
            res.push(
                <i
                    className={`form-icon icon icon-arrow-${isOpen ? 'up' : 'down'} c-hand`}
                    {...getToggleButtonProps({ disabled })}
                    key="arrow"
                />
            );
        }

        return res;
    }

    getDisplayItems = (items, value) => {
        // In questo modo non mi aggiorna le opzioni mentre scrivo, il che non è del tutto desiderabile...
        // if (this.props.autoSortItems === false) {
        //     return items;
        // }

        // console.warn(this.props.clearable);
        // console.warn(this.props.selectedItem);
        // console.warn(items);
        // console.warn(value);

        if (this.props.clearable === false && this.props.selectedItem !== null) {
            return items;
        }

        if (value === '' || value === null) {
            return items;
        }

        // const result = matchAndSort(items, value, [this.props.itemLabelKey]);

        // console.log(profiler.log());

        // TODO: sarebbe il caso di memorizzare il risultato...
        items = items.map(item => {
            return {
                ...item,
                localizedLabel: this.props.itemToString(item)
            };
        });

        const result = matchSorter(items, value, {
            keys: ['localizedLabel'],
            threshold: matchSorter.rankings.CONTAINS
        });

        // const result = fuzzyFilter(items, value);

        // return result;

        return sortBy(result, r => r.localizedLabel.toLowerCase());
    };

    handleChange = item => {
        const { onChange, selectedItem } = this.props;

        if (item === null) {
            return onChange(item);
        }

        if (selectedItem && selectedItem.value === item.value) {
            return true;
        }

        onChange(item);
    };

    renderNormalList(
        displayItems,
        highlightedIndex,
        selectedItem,
        inputValue,
        itemValueKey,
        itemToString,
        getItemProps
    ) {
        return (
            <div className="menu" style={{ transform: 'none', padding: 0 }}>
                {displayItems.map((item, index) => {
                    const itemClassName = cx('dropdownList-menu-item', 'c-hand', {
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem === item
                    });

                    return (
                        <div
                            key={item[itemValueKey]}
                            className={itemClassName}
                            {...getItemProps({
                                index,
                                item
                            })}
                        >
                            {inputValue === '' ? (
                                <span>{itemToString(item)}</span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: fuzzaldrin.wrap(itemToString(item), inputValue)
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderVirtualList(
        displayItems,
        highlightedIndex,
        selectedItem,
        inputValue,
        itemValueKey,
        itemToString,
        getItemProps
    ) {
        return (
            <VirtualList
                width="100%"
                scrollToIndex={highlightedIndex || 0}
                scrollToAlignment="auto"
                height={displayItems.length < 10 ? displayItems.length * 34 : 340}
                itemCount={displayItems.length}
                itemSize={34}
                renderItem={({ index, style }) => {
                    const item = displayItems[index];

                    const itemClassName = cx('dropdownList-menu-item', 'c-hand', {
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem === item
                    });

                    return (
                        <div
                            key={item[itemValueKey]}
                            className={itemClassName}
                            {...getItemProps({
                                index,
                                item,
                                style
                            })}
                        >
                            {inputValue === '' ? (
                                <span>{itemToString(item)}</span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: fuzzaldrin.wrap(itemToString(item), inputValue)
                                    }}
                                />
                            )}
                            {/* <Highlighter
                                highlightClassName="search-highlight"
                                highlightTag="span"
                                searchWords={[
                                    inputValue
                                ]}
                                autoEscape={true}
                                textToHighlight={itemToString(
                                    item
                                )}
                            /> */}
                        </div>
                    );
                }}
            />
        );
    }

    // componentDidUpdate(prevProps) {
    //     console.warn('Diff: ', prevProps.items !== this.props.items);
    // }

    render() {
        const {
            clearable,
            disabled,
            items,
            itemToString,
            isLoading,
            placeholder,
            itemLabelKey,
            itemValueKey,
            onChange,
            i18n,
            wrapperStyle,
            onWrapperClick,
            onFocus,
            onBlur,
            className,
            inputClassName,
            useVirtualList,
            ...rest
        } = this.props;

        return (
            <Downshift
                itemToString={itemToString}
                itemCount={items.length}
                onChange={this.handleChange}
                {...rest}
            >
                {({
                    getInputProps,
                    getToggleButtonProps,
                    getItemProps,
                    isOpen,
                    // toggleMenu,
                    clearSelection,
                    selectedItem,
                    inputValue,
                    highlightedIndex
                }) => {
                    const displayItems = this.getDisplayItems(items, inputValue);

                    // Non mostro niente nell'input se il menu è chiuso e non e stato selezionato nessun oggetto
                    const defaultInputProps = getInputProps({
                        disabled,
                        className: inputClassName
                    });

                    if (
                        (isOpen === false && selectedItem === null) ||
                        defaultInputProps.value === null
                    ) {
                        defaultInputProps.value = '';
                    }

                    const renderList = useVirtualList
                        ? this.renderVirtualList
                        : this.renderNormalList;

                    const dropdownClassName = cx('dropdownList', 'has-icon-right', className, {
                        isDisabled: this.props.disabled
                    });

                    // console.log(defaultInputProps);

                    return (
                        <div
                            className={dropdownClassName}
                            style={wrapperStyle}
                            onClick={onWrapperClick}
                        >
                            <input
                                type="text"
                                placeholder={
                                    placeholder ? i18n._(placeholder) : i18n._('filter:by')
                                }
                                {...defaultInputProps}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            />
                            {this.renderIcon(
                                selectedItem,
                                isOpen,
                                clearSelection,
                                getToggleButtonProps
                            )}
                            {!isOpen ? null : (
                                <div className="dropdownList-menu">
                                    {displayItems.length === 0 ? (
                                        <div className="dropdownList-menu-item">
                                            {isLoading ? (
                                                <Trans id="Please wait" />
                                            ) : (
                                                <Trans id="no:result" />
                                            )}
                                        </div>
                                    ) : (
                                        renderList(
                                            displayItems,
                                            highlightedIndex,
                                            selectedItem,
                                            inputValue,
                                            itemValueKey,
                                            itemToString,
                                            getItemProps
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

DropdownList.propTypes = {
    autoSortItems: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    itemToString: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onWrapperClick: PropTypes.func,
    itemLabelKey: PropTypes.string,
    itemValueKey: PropTypes.string,
    placeholder: PropTypes.string,
    showClearAndSelectBtn: PropTypes.bool,
    wrapperStyle: PropTypes.object,
    inputClassName: PropTypes.string,
    useVirtualList: PropTypes.bool
};

DropdownList.defaultProps = {
    autoSortItems: true,
    className: '',
    clearable: true,
    disabled: false,
    isLoading: false,
    items: [],
    itemToString: i => (i ? i.label : ''),
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onWrapperClick: () => {},
    itemLabelKey: 'label',
    itemValueKey: 'value',
    showClearAndSelectBtn: true,
    wrapperStyle: {},
    inputClassName: '',
    useVirtualList: true
};

export default withI18n()(DropdownList);
