import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Motion, spring, presets } from 'react-motion';

import ArticleDetails from './ArticleDetails';

export default class ArticleDetailsWrapper extends Component {
    getStyle() {
        if (this.props.open) {
            return {
                minHeight: spring(480, presets.gentle)
            };
        }

        return {
            minHeight: spring(0)
        };
    }

    render() {
        const { open, tdClass } = this.props;

        return (
            <tr className={cx('article-row-details', { open })}>
                <td colSpan="99" className={tdClass}>
                    <Motion
                        style={this.getStyle()}
                        defaultStyle={{ minHeight: 0 }}
                    >
                        {interpolatedStyle => (
                            <div
                                className={cx('article-row-details-content', {
                                    open
                                })}
                                style={interpolatedStyle}
                            >
                                {open ? (
                                    <ArticleDetails {...this.props} />
                                ) : (
                                    <div />
                                )}
                            </div>
                        )}
                    </Motion>
                </td>
            </tr>
        );
    }
}

ArticleDetailsWrapper.propTypes = {
    open: PropTypes.bool,
    tdClass: PropTypes.string
};
