import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Trans, i18nMark, withI18n } from '@lingui/react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import MatchWhenGuest from './components/routing/MatchWhenGuest';
import InputField from './components/form/InputField';
import SubmitButton from './components/form/SubmitButton';
import AppLogo from './components/utils/AppLogo';
import { resetPassword } from './api';

function validate(values) {
    const mandatoryFieldError = i18nMark('validation:error:mandatory:field');
    const errors = {};

    if (!values.password) {
        errors.password = mandatoryFieldError;
    }

    if (!values.password_confirm) {
        errors.password_confirm = mandatoryFieldError;
    }

    if (values.password !== values.password_confirm) {
        errors.password_confirm = i18nMark('validation:error:password:confirm:mismatch');
    }

    return errors;
}

export class ResetPassword extends Component {
    state = {
        missingToken: false
    };

    componentDidMount() {
        this.params = new URLSearchParams(window.location.search);

        if (this.params.has('t') === false) {
            this.setState({
                missingToken: true
            });
        }

        setImmediate(() => {
            this.form.elements[0].focus();
        });
    }

    onSubmit = async data => {
        const { i18n } = this.props;
        // TODO: spostare tutta questa logica in una saga?
        // console.warn(data);
        try {
            await resetPassword(data, this.params.get('t'));

            toast(i18n._('password:reset:success'), {
                position: 'bottom-right',
                type: toast.TYPE.SUCCESS,
                autoClose: 10000,
                onClose: () => this.props.history.push('/login')
            });
        } catch (err) {
            // console.error(err);
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                if (err.response.status === 409) {
                    errorMessage = i18n._('change:password:error:stale');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage
            };
        }
    };

    render() {
        const { missingToken } = this.state;

        return (
            <div className="bg-primary login-wrapper">
                <div className="container">
                    <div className="columns">
                        <div className="column col-4 col-xl-5 col-mx-auto">
                            <Form
                                onSubmit={this.onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    pristine,
                                    // hasValidationErrors,
                                    submitError,
                                    submitting,
                                    submitSucceeded
                                    // ...rest
                                }) => {
                                    // console.warn(rest);

                                    return (
                                        <form
                                            ref={form => (this.form = form)}
                                            onSubmit={handleSubmit}
                                            className="form-spaced"
                                        >
                                            <div
                                                className="text-center"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <AppLogo />
                                            </div>
                                            {missingToken ? (
                                                <div className="bg-error mb-2 p-2 text-center">
                                                    <Trans id="password:reset:form:missing:token:error" />
                                                </div>
                                            ) : (
                                                <p className="text-dark">
                                                    <Trans id="password:reset:form:title" />
                                                </p>
                                            )}
                                            <div className="form-group">
                                                <Field
                                                    name="password"
                                                    component={InputField}
                                                    placeholder={i18nMark('password')}
                                                    mandatory={true}
                                                    inputType="password"
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    name="password_confirm"
                                                    component={InputField}
                                                    placeholder={i18nMark('password:confirm')}
                                                    mandatory={true}
                                                    inputType="password"
                                                    className="form-input input-lg"
                                                />
                                            </div>

                                            <SubmitButton
                                                submitting={submitting}
                                                disabled={
                                                    missingToken || pristine || submitSucceeded
                                                }
                                            >
                                                <Trans id="confirm" />
                                            </SubmitButton>
                                            {submitError && (
                                                <div className="bg-error mt-2 p-2 text-center">
                                                    {submitError}
                                                </div>
                                            )}
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: '20px' }}>
                        <div className="column col-4 col-xl-5 col-mx-auto">
                            <Link to="/login" style={{ color: '#fff' }}>
                                <i className="icon icon-back" /> <Trans id="back:to:login" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ResetPasswordIntl = withI18n()(ResetPassword);

export default withRouter(connect()(MatchWhenGuest(ResetPasswordIntl)));
