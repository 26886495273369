import React, { Component } from 'react';
import { withI18n } from '@lingui/react';

import LogoUploader from './form/LogoUploader';
import 'filepond/dist/filepond.min.css';

export class CustomizationPanel extends Component {
    render() {
        return (
            <div className="container grid-lg">
                <div className="columns">
                    <div className="column col-6">
                        <LogoUploader
                            type="full"
                            height={50}
                            helperText={`${this.props.i18n._('suggested:size')}: 240x50px`}
                        />
                        <LogoUploader
                            type="header"
                            height={40}
                            helperText={`${this.props.i18n._('suggested:size')}: 40x40px`}
                            withBackground={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withI18n()(CustomizationPanel);
