import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';

import ArticleRecap from './ArticleRecap';
import ArticleDetailsCategoryTab from './ArticleDetailsCategoryTab';
import ArticleDetailsModal from './ArticleDetailsModal';
import noPhotoImg from '../images/no-photo.png';
import { getAssetURL } from '../api';
// import { normalizeArticleDetails } from '../helpers';
import { fetchItemDetails } from '../helpers';

class ArticleDetails extends PureComponent {
    constructor(props) {
        super(props);

        // this.itemDataCategories = Object.values(ARTICLE_DETAIL_SECTIONS);

        // console.warn(this.itemDataCategories);

        this.state = {
            isFetching: true,
            currentCategory: null,
            details: {},
            etimClassDetails: {},
            // dataInputValue: 'all',
            showModal: false
        };
    }

    async componentDidMount() {
        const articleDetails = await fetchItemDetails(this.props.item.id);

        this.setState({
            details: articleDetails,
            etimClassDetails: articleDetails.etim,
            isFetching: false
        });
    }

    // handleDataInputchange = e => {
    //     this.setState({
    //         dataInputValue: e.target.dataset.value
    //     });
    // };

    chooseTab = key => {
        this.setState({
            currentCategory: key,
            showModal: true
        });
    };

    // showModal = () => {
    //     this.setState({
    //         showModal: true
    //     });
    // };

    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    render() {
        const {
            item,
            language,
            productProperties
            // itemDataCategories,
            // itemDataChosenCategoriesId
        } = this.props;

        const { details, showModal, currentCategory, etimClassDetails } = this.state;

        // const categories = this.getCategories();

        if (this.state.isFetching) {
            return (
                <div className="container">
                    <div className="columns">
                        <div className="column col-12">
                            <div className="text-center">
                                <i className="form-icon loading" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Fragment>
                <div className="container">
                    <div className="columns">
                        <div className="column col-9">
                            <ArticleRecap
                                item={item}
                                details={details}
                                language={language}
                                productProperties={productProperties}
                            />
                        </div>
                        <div className="column col-3">
                            <div className="article-details-img-wrapper">
                                <Img
                                    src={
                                        item.immagine_prodotto
                                            ? getAssetURL(item.immagine_prodotto.url)
                                            : noPhotoImg
                                    }
                                    className="img-responsive m-a"
                                    style={{ maxHeight: '260px' }}
                                    loader={
                                        <div className="rounded bg-secondary table-img-preview" />
                                    }
                                />
                            </div>
                        </div>
                        <ArticleDetailsCategoryTab
                            onTabClick={this.chooseTab}
                            activeCategories={[]}
                            details={details}
                        />
                    </div>
                </div>
                {showModal && (
                    <ArticleDetailsModal
                        article={item}
                        details={details}
                        initialCategories={[currentCategory]}
                        onClose={this.closeModal}
                        etimClassDetails={etimClassDetails}
                        language={language}
                    />
                )}
            </Fragment>
        );
    }
}

ArticleDetails.propTypes = {
    item: PropTypes.object,
    language: PropTypes.string,
    productProperties: PropTypes.object
    // itemDataChosenCategories: PropTypes.object,
    // itemDataChosenCategoriesId: PropTypes.object
};

export default ArticleDetails;
