import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Trans, i18nMark, withI18n } from '@lingui/react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import MatchWhenGuest from './components/routing/MatchWhenGuest';
import InputField from './components/form/InputField';
import SubmitButton from './components/form/SubmitButton';
import AppLogo from './components/utils/AppLogo';
import { forgotPassword } from './api';
import { validateEmail } from './form-helpers';

function validate(values) {
    const errors = {};

    if (!values.email) {
        errors.email = i18nMark('validation:error:mandatory:field');
    } else if (!validateEmail(values.email)) {
        errors.email = i18nMark('validation:error:email:invalid');
    }

    return errors;
}

export class PasswordLost extends Component {
    componentDidMount() {
        setImmediate(() => {
            this.form.elements[0].focus();
        });
    }

    onSubmit = async data => {
        const { i18n } = this.props;
        // TODO: spostare tutta questa logica in una saga?
        // console.warn(data);
        try {
            await forgotPassword(data);

            toast(i18n._('password:lost:success:message'), {
                position: 'bottom-right',
                type: toast.TYPE.SUCCESS,
                autoClose: 10000,
                onClose: () => this.props.history.push('/login')
            });

            // setTimeout(() => {
            //     this.props.history.push('/login');
            // }, 8000);
        } catch (err) {
            // console.error(err);
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                if (err.response.status === 401) {
                    errorMessage = i18n._('user:not:found:error');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage
            };
        }
    };

    render() {
        return (
            <div className="bg-primary login-wrapper">
                <div className="container">
                    <div className="columns">
                        <div className="column col-4 col-xl-5 col-mx-auto">
                            <Form
                                onSubmit={this.onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    pristine,
                                    // hasValidationErrors,
                                    submitError,
                                    submitting,
                                    submitSucceeded
                                    // ...rest
                                }) => {
                                    // console.warn(rest);

                                    return (
                                        <form
                                            ref={form => (this.form = form)}
                                            onSubmit={handleSubmit}
                                            className="form-spaced"
                                        >
                                            <div
                                                className="text-center"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <AppLogo />
                                                <p className="text-dark">
                                                    <Trans id="password:lost:form:title" />
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    name="email"
                                                    component={InputField}
                                                    placeholder={i18nMark('email')}
                                                    mandatory={true}
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <SubmitButton
                                                submitting={submitting}
                                                disabled={pristine || submitSucceeded}
                                            >
                                                <Trans id="confirm" />
                                            </SubmitButton>
                                            {submitError && (
                                                <div className="bg-error mt-2 p-2 text-center">
                                                    {submitError}
                                                </div>
                                            )}
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: '20px' }}>
                        <div className="column col-4 col-xl-5 col-mx-auto">
                            <Link to="/login" style={{ color: '#fff', fontWeight: 700 }}>
                                <i className="icon icon-back" /> <Trans id="back:to:login" />
                            </Link>
                        </div>
                        {/* <div className="column col-2 col-mr-auto text-right">
                            <span className="">
                                <Link
                                    to="/signup"
                                    style={{ color: '#fff', fontWeight: 700 }}
                                >
                                    Registrati
                                </Link>
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

const PasswordLostIntl = withI18n()(PasswordLost);

export default withRouter(connect()(MatchWhenGuest(PasswordLostIntl)));
