import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withI18n, i18nMark } from '@lingui/react';
import cx from 'classnames';

import Loader from './utils/Loader';
import ArticleTableHeader from './ArticleTableHeader';
import ProductClassFilterWrapper from '../containers/ProductClassFilterWrapper';
import ArticleTableFilters from '../containers/ArticleTableFilters';
import SortableTableHeader from './utils/SortableTableHeader';
import CollectionsList from '../containers/CollectionsList';
import ArticlePagination from './ArticlePagination';
import CollectionControlsHeader from './CollectionControlsHeader';
import Row from './Row';
import RowBlock from '../containers/RowBlock';
import { VIEW_TYPE_DETAILED, VIEW_TYPE_COMPACT, VIEW_TYPE_BLOCK } from '../constants';

const paginationItemsString = i18nMark('pagination:items');

export class ArticleTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewType: props.defaultTableView || VIEW_TYPE_COMPACT,
            showCollections: false
        };
    }

    toggleCollections = () => {
        this.setState(prevState => ({
            showCollections: !prevState.showCollections
        }));
    };

    onSetViewType = type => {
        this.setState({
            viewType: type
        });
    };

    renderTableHeader() {
        const {
            sortBy,
            sorting,
            areRowsSelectable,
            isAnyFilterActive,
            onSelectAll,
            onRemoveAll
        } = this.props;

        if (this.state.viewType === VIEW_TYPE_DETAILED) {
            return (
                <tr>
                    <th />
                    {areRowsSelectable && (
                        <CollectionControlsHeader
                            selectAllDisabled={isAnyFilterActive === false}
                            onSelectAll={onSelectAll}
                            onRemoveAll={onRemoveAll}
                        />
                    )}
                    <SortableTableHeader attr="codice_articolo" onClick={sortBy} sorting={sorting}>
                        <Trans id="article:code" />
                    </SortableTableHeader>
                    <SortableTableHeader
                        attr="descrizione_articolo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        <Trans id="article:description" />
                    </SortableTableHeader>
                    <SortableTableHeader attr="marca" onClick={sortBy} sorting={sorting}>
                        <Trans id="brand" />
                    </SortableTableHeader>
                    <th>
                        <Trans id="series" />
                    </th>
                    <th>
                        <Trans id="line" />
                    </th>
                    <SortableTableHeader attr="modello" onClick={sortBy} sorting={sorting}>
                        <Trans id="model" />
                    </SortableTableHeader>
                    <th className="text-center">
                        <Trans id="um" /> <Trans id="conf" />
                    </th>
                    <SortableTableHeader
                        headerClass="text-right"
                        attr="prezzo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        <span style={{ paddingRight: '25px' }}>
                            <Trans id="price" />
                        </span>
                    </SortableTableHeader>
                </tr>
            );
        }

        if (this.state.viewType === VIEW_TYPE_BLOCK) {
            return (
                <tr>
                    {areRowsSelectable && (
                        <CollectionControlsHeader
                            selectAllDisabled={isAnyFilterActive === false}
                            onSelectAll={onSelectAll}
                            onRemoveAll={onRemoveAll}
                        />
                    )}
                    <SortableTableHeader attr="codice_articolo" onClick={sortBy} sorting={sorting}>
                        <Trans id="article:code" />
                    </SortableTableHeader>
                    <SortableTableHeader
                        attr="descrizione_articolo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        <Trans id="article:description" />
                    </SortableTableHeader>
                    <SortableTableHeader attr="marca" onClick={sortBy} sorting={sorting}>
                        <Trans id="brand" />
                    </SortableTableHeader>
                    <SortableTableHeader attr="modello" onClick={sortBy} sorting={sorting}>
                        <Trans id="model" />
                    </SortableTableHeader>
                    <SortableTableHeader
                        headerClass="text-right"
                        attr="prezzo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        <span style={{ paddingRight: '25px' }}>
                            <Trans id="price" />
                        </span>
                    </SortableTableHeader>
                </tr>
            );
        }

        return (
            <tr>
                <SortableTableHeader
                    headerClass="table-cell-brand"
                    attr="marca"
                    onClick={sortBy}
                    sorting={sorting}
                >
                    <Trans id="brand" />
                </SortableTableHeader>
                <SortableTableHeader
                    headerClass="table-cell-code"
                    attr="codice_articolo"
                    onClick={sortBy}
                    sorting={sorting}
                >
                    <Trans id="article:code" />{' '}
                </SortableTableHeader>
                {areRowsSelectable && (
                    <CollectionControlsHeader
                        selectAllDisabled={isAnyFilterActive === false}
                        onSelectAll={onSelectAll}
                        onRemoveAll={onRemoveAll}
                    />
                )}
                <SortableTableHeader attr="descrizione_articolo" onClick={sortBy} sorting={sorting}>
                    <Trans id="article:description" />
                </SortableTableHeader>
                <th
                    className="tooltip"
                    data-tooltip={this.props.i18n._('measure:unit')}
                    style={{ width: '45px' }}
                >
                    <Trans id="um" />
                </th>
                <SortableTableHeader
                    headerClass="text-right table-cell-price"
                    attr="prezzo"
                    onClick={sortBy}
                    sorting={sorting}
                >
                    <Trans id="price" />
                </SortableTableHeader>
            </tr>
        );
    }

    renderRows() {
        const { items, viewType, language, areRowsSelectable, onSelectRow, q } = this.props;

        let i = 0;

        if (items.size === 0) {
            return (
                <tr>
                    <td colSpan="999" className="bg-gray">
                        <div className="empty">
                            <div className="empty-icon">
                                <i className="icon icon-search" />
                            </div>
                            <p className="empty-title h5">
                                <Trans id="article:count" values={{ count: 0 }} />
                            </p>
                            <p className="empty-subtitle">
                                <Trans id="Use filters to re-search" />
                            </p>
                        </div>
                    </td>
                </tr>
            );
        }

        if (viewType === VIEW_TYPE_BLOCK) {
            return null;
        }

        return items.map(item => {
            i++;

            return (
                <Row
                    key={item.id}
                    item={item}
                    index={i}
                    type={this.state.viewType}
                    q={q}
                    language={language}
                    selectable={areRowsSelectable}
                    onSelectRow={onSelectRow}
                />
            );
        });
    }

    renderBlocks() {
        const { items, language, areRowsSelectable, onSelectRow, q } = this.props;

        let i = 0;

        return (
            <div className="article-block-container">
                {items.map(item => {
                    i++;

                    return (
                        <RowBlock
                            key={item.id}
                            item={item}
                            index={i}
                            language={language}
                            q={q}
                            selectable={areRowsSelectable}
                            onSelect={onSelectRow}
                        />
                    );
                })}
            </div>
        );
    }

    render() {
        const {
            isFetching,
            total,
            onPageChange,
            currentPage,
            pages,
            lastSearchHash
            // user
        } = this.props;

        const { viewType, showCollections } = this.state;

        // const isAdmin = user.ruolo === 'admin';

        return (
            <div className="container">
                <ArticleTableHeader
                    viewType={viewType}
                    setViewType={this.onSetViewType}
                    total={total}
                    displayArticleNumber={lastSearchHash !== null && isFetching === false}
                    onCollectionsBtnClick={this.toggleCollections}
                    isCollectionsShowed={showCollections}
                />
                {showCollections && <CollectionsList />}
                <ArticleTableFilters />
                <ProductClassFilterWrapper />
                <div className="columns">
                    <table
                        className={cx('table', 'article-table', {
                            'table-dense': viewType === VIEW_TYPE_COMPACT
                        })}
                    >
                        <thead>{this.renderTableHeader()}</thead>
                        <tbody>
                            {isFetching ? (
                                <tr>
                                    <td colSpan="999">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                this.renderRows()
                            )}
                        </tbody>
                    </table>
                    {viewType === VIEW_TYPE_BLOCK && isFetching === false && this.renderBlocks()}
                </div>
                {isFetching === false && (
                    <ArticlePagination
                        onPageChange={onPageChange}
                        currentPage={currentPage}
                        pages={pages}
                        total={total}
                        itemTranslationKey={paginationItemsString}
                    />
                )}
            </div>
        );
    }
}

ArticleTable.propTypes = {
    i18n: PropTypes.object,
    areRowsSelectable: PropTypes.bool,
    isFetching: PropTypes.bool,
    items: PropTypes.object,
    sortBy: PropTypes.func,
    sorting: PropTypes.object,
    total: PropTypes.number,
    q: PropTypes.string,
    onPageChange: PropTypes.func,
    currentPage: PropTypes.number,
    pages: PropTypes.number,
    isAnyFilterActive: PropTypes.bool,
    language: PropTypes.string
};

ArticleTable.defaultProps = {
    items: []
};

export default withI18n()(ArticleTable);
