import React, { Component, Fragment } from 'react';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as constants from '../constants';
import { getOptionLabelLocalized } from '../intl-helpers';

class ProductClassFilterActiveBlock extends Component {
    render() {
        const { label, children } = this.props;

        return (
            <div className="float-left">
                <span className="text-bold">{label}:</span> {children}
            </div>
        );
    }
}

class ProductClassFilterActiveLabel extends Component {
    render() {
        const { label, onClear } = this.props;

        return (
            <span className="label label-secondary" style={{ marginRight: '5px' }}>
                {label}{' '}
                <i
                    onClick={onClear}
                    className="icon icon-cross c-hand"
                    style={{
                        position: 'relative',
                        top: '-2px'
                    }}
                />
            </span>
        );
    }
}

class ProductClassFilterActive extends Component {
    renderActiveFilters() {
        const { i18n, language } = this.props;
        // console.warn(this.props.attributes);

        let blocks = [];

        this.props.activeFilters.forEach((values, key) => {
            const attribute = this.props.attributes.find(a => a.key === key);

            if (attribute) {
                switch (attribute.type) {
                    case constants.ATTRIBUTE_TYPE_ALPHANUMERIC:
                    default:
                        blocks.push(
                            <ProductClassFilterActiveBlock
                                label={getOptionLabelLocalized(language)(attribute)}
                                key={key}
                            >
                                <Fragment>
                                    {values.map(v => {
                                        //const option = attribute.filters.find(
                                        //    f => f.value === v
                                        //);

                                        const option = _.find(attribute.data, f => f.value === v);

                                        return (
                                            <ProductClassFilterActiveLabel
                                                label={
                                                    attribute.type ===
                                                    constants.ATTRIBUTE_TYPE_RANGE
                                                        ? option.label
                                                        : getOptionLabelLocalized(language)(option)
                                                }
                                                key={option.value}
                                                onClear={() =>
                                                    this.props.onCheckboxToggle(
                                                        attribute.key,
                                                        option.value
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                </Fragment>
                            </ProductClassFilterActiveBlock>
                        );
                        break;
                    case constants.ATTRIBUTE_TYPE_LOGIC:
                        blocks.push(
                            <ProductClassFilterActiveBlock
                                label={getOptionLabelLocalized(language)(attribute)}
                                key={key}
                            >
                                <ProductClassFilterActiveLabel
                                    label={values === 'yes' ? i18n._('yes') : i18n._('no')}
                                    onClear={() => this.props.onChangeBooleanField(key, 'all')}
                                />
                            </ProductClassFilterActiveBlock>
                        );
                        break;
                    case constants.ATTRIBUTE_TYPE_NUMERIC:
                        const [start, end] = values;

                        blocks.push(
                            <ProductClassFilterActiveBlock
                                label={getOptionLabelLocalized(language)(attribute)}
                                key={key}
                            >
                                <ProductClassFilterActiveLabel
                                    label={i18n._('between:start:end', {
                                        start,
                                        end
                                    })}
                                    onClear={() => this.props.onAttributeResetClick(key)}
                                />
                            </ProductClassFilterActiveBlock>
                        );
                }
            }
        });

        return <Fragment>{blocks}</Fragment>;
    }

    render() {
        return <div className="column col-12 mb-2">{this.renderActiveFilters()}</div>;
    }
}

ProductClassFilterActive.propTypes = {
    activeFilters: PropTypes.object,
    attributes: PropTypes.object,
    language: PropTypes.string,
    // noTextActiveFilters: PropTypes.object,
    onCheckboxToggle: PropTypes.func.isRequired
};

ProductClassFilterActive.defaultProps = {
    activeFilters: {},
    attributes: []
};

export default withI18n()(ProductClassFilterActive);
