import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, i18nMark, withI18n } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import MatchWhenGuest from './components/routing/MatchWhenGuest';
import InputField from './components/form/InputField';
import SubmitButton from './components/form/SubmitButton';
import AppLogo from './components/utils/AppLogo';
import { login } from './api';
import { authSuccessfull } from './actions/userActions';
import { validateEmail } from './form-helpers';

function validate(values) {
    const errors = {};

    if (!values.email) {
        errors.email = i18nMark('validation:error:email:mandatory');
    } else if (!validateEmail(values.email)) {
        errors.email = i18nMark('validation:error:email:invalid');
    }

    if (!values.password) {
        errors.password = i18nMark('validation:error:password');
    }

    return errors;
}

export class Login extends Component {
    componentDidMount() {
        setImmediate(() => {
            this.form.elements[0].focus();
        });
    }

    onSubmit = async data => {
        const { i18n } = this.props;

        // TODO: spostare tutta questa logica in una saga?
        // console.warn(data);
        try {
            const res = await login(data);
            // console.warn(res);
            this.props.dispatch(authSuccessfull(res.data));
        } catch (err) {
            // console.error(err);
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                // console.log(err.response.data);
                // console.log(err.response.status);
                if (err.response.status === 401 || err.response.status === 412) {
                    errorMessage = i18n._('error:login:invalid');
                } else if (err.response.status === 403) {
                    errorMessage = i18n._('error:login:user:disabled');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage
            };
        }
    };

    render() {
        return (
            <div className="bg-primary login-wrapper">
                <div className="container">
                    <div className="columns">
                        <div className="column col-4 col-xl-5 col-mx-auto">
                            <Form
                                onSubmit={this.onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    pristine,
                                    // hasValidationErrors,
                                    submitError,
                                    submitting
                                    // ...rest
                                }) => {
                                    // console.warn(rest);

                                    return (
                                        <form
                                            ref={form => (this.form = form)}
                                            onSubmit={handleSubmit}
                                            className="form-spaced"
                                            style={{ paddingBottom: '30px' }}
                                        >
                                            <div
                                                className="text-center"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <AppLogo />
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    name="email"
                                                    component={InputField}
                                                    placeholder={i18nMark('email')}
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    name="password"
                                                    component={InputField}
                                                    placeholder={i18nMark('password')}
                                                    className="form-input input-lg"
                                                    inputType="password"
                                                />
                                            </div>
                                            <SubmitButton
                                                submitting={submitting}
                                                disabled={pristine}
                                            >
                                                <Trans id="enter" />
                                            </SubmitButton>
                                            {submitError && (
                                                <div className="bg-error mt-2 p-2 text-center">
                                                    {submitError}
                                                </div>
                                            )}
                                            <div
                                                className="text-gray text-center"
                                                style={{ marginTop: '10px' }}
                                            >
                                                <Trans id="browser:suggested" />{' '}
                                                <a
                                                    href="https://www.google.com/intl/it_ALL/chrome/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        textDecoration: 'underline'
                                                    }}
                                                >
                                                    Google Chrome
                                                </a>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: '20px' }}>
                        <div className="column col-2 col-xl-3 col-ml-auto">
                            <Link to="/password-lost" style={{ color: '#fff' }}>
                                <Trans id="Password lost?" />
                            </Link>
                        </div>
                        <div className="column col-2 col-mr-auto text-right">
                            <span className="">
                                <Link to="/signup" style={{ color: '#fff', fontWeight: 700 }}>
                                    <Trans id="register" />
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const LoginIntl = withI18n()(Login);

export default connect()(MatchWhenGuest(LoginIntl));
