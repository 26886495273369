import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans /*, NumberFormat*/ } from '@lingui/react';
import cx from 'classnames';

import { getIntlNumberFormat } from '../intl-helpers';
import { VIEW_TYPE_COMPACT, VIEW_TYPE_DETAILED, VIEW_TYPE_BLOCK } from '../constants';

const intl = getIntlNumberFormat();

class ArticleTableHeader extends Component {
    render() {
        const {
            viewType,
            setViewType,
            total,
            displayArticleNumber,
            isCollectionsShowed
        } = this.props;

        // TODO: bug in visualizzazione traduzione, con numeri maggiori di 1.000, le cifre dopo il . vengono interpretate
        // come decimali e quindi tagliate a 2, indagare...
        return (
            <div className="columns">
                <div className="column col-10">
                    <h2 className="text-primary">
                        {displayArticleNumber && (
                            <Fragment>
                                {total < 2 ? (
                                    <Trans id="article:count" values={{ count: total }} />
                                ) : (
                                    <span>
                                        {intl.format(total)} <Trans id="articles" />
                                    </span>
                                )}
                            </Fragment>
                        )}
                        <button
                            className={cx('btn btn-sm text-uppercase', {
                                'btn-secondary': viewType === VIEW_TYPE_COMPACT,
                                'btn-link': viewType !== VIEW_TYPE_COMPACT,
                                'ml-2': total > 0
                            })}
                            onClick={() => setViewType(VIEW_TYPE_COMPACT)}
                        >
                            <i className="icon icon-menu" /> <Trans id="view:compact" />
                        </button>
                        <button
                            className={`btn btn-sm btn-${
                                viewType === VIEW_TYPE_DETAILED ? 'secondary' : 'link'
                            } text-uppercase btn-detailed`}
                            onClick={() => setViewType(VIEW_TYPE_DETAILED)}
                        >
                            <i className="icon icon-apps" /> <Trans id="view:detailed" />
                        </button>
                        <button
                            className={`btn btn-sm btn-${
                                viewType === VIEW_TYPE_BLOCK ? 'secondary' : 'link'
                            } text-uppercase`}
                            onClick={() => setViewType(VIEW_TYPE_BLOCK)}
                        >
                            <i className="icon icon-photo" /> <Trans id="view:block" />
                        </button>
                    </h2>
                </div>
                <div className="column col-2 mt-2 text-right">
                    {/* <button className="btn btn-sm">
                        <i className="icon icon-download" />{' '}
                        <Trans id="export:catalog" />
                    </button> */}
                    <button
                        className={cx('btn btn-sm btn-collections text-uppercase', {
                            'btn-primary': isCollectionsShowed
                        })}
                        onClick={this.props.onCollectionsBtnClick}
                    >
                        <i className="icon icon-bookmark" /> <Trans id="collections" />
                    </button>
                </div>
            </div>
        );
    }
}

ArticleTableHeader.propTypes = {
    viewType: PropTypes.string,
    setViewType: PropTypes.func,
    total: PropTypes.number,
    displayArticleNumber: PropTypes.bool,
    onCollectionsBtnClick: PropTypes.func,
    showCollectionBtn: PropTypes.bool
};

ArticleTableHeader.defaultProps = {
    showCollectionBtn: false
};

export default ArticleTableHeader;
