import { call, put, select, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { actions, actionTypes } from '../actions/usersActions';
import { fetchUtenti } from '../api';
import { selectors } from '../reducers/usersReducer';

function getApiPayload(filters, sorting, currentPage, perPage) {
    let payload = {
        page: currentPage + 1,
        per_page: perPage
    };

    if (filters.q !== '') {
        payload.q = filters.q;
    }

    if (filters.enabled !== '') {
        payload.enabled = filters.enabled === '1' ? true : false;
    }

    if (filters.locked !== '') {
        payload.locked = filters.locked === '1' ? true : false;
    }

    if (filters.provincia !== null) {
        payload.sigla_provincia = filters.provincia.value;
    }

    if (filters.attivita !== null) {
        payload.attivita = filters.attivita.value;
    }

    if (filters.group_id !== null) {
        payload.group = filters.group_id.value;
    }

    if (sorting.sortBy !== null) {
        payload.sort = sorting.sortBy;
        payload.sort_direction = sorting.sortDirection.toUpperCase();
    }

    return payload;
}

function* fetchUsers(action) {
    const filters = yield select(selectors.getFilters);
    const sorting = yield select(selectors.getSorting);
    const currentPage = yield select(selectors.getCurrentPage);
    const perPage = yield select(selectors.getPerPage);

    const apiPayload = getApiPayload(filters, sorting, currentPage, perPage);

    try {
        yield put(showLoading('itemsFetch'));

        yield put(actions.fetchUsersStart());

        const res = yield call(fetchUtenti, apiPayload);

        yield put(actions.fetchUsersSuccess(res));

        yield put(hideLoading('itemsFetch'));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);

        yield put(hideLoading('itemsFetch'));

        yield put(actions.fetchUsersFail(err));
    }
}

export default [
    takeEvery(
        [
            actionTypes.FETCH_USERS,
            actionTypes.SET_USERS_FILTER,
            actionTypes.SORT_USERS_BY,
            actionTypes.CHANGE_USERS_PAGE,
            actionTypes.RESET_USERS_FILTERS
        ],
        fetchUsers
    )
];
