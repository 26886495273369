import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { batchedSubscribe } from 'redux-batched-subscribe';
// import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import createSagaMiddleware, { END } from 'redux-saga';
import persistState from 'redux-localstorage';
// import Immutable from 'seamless-immutable';

import reducer from '../reducers';
import { UserRecord, DEFAULT_STATE } from '../reducers/userReducer';
import { AppRecord } from '../reducers/appReducer';
import { getShortCodeFromLocale } from '../intl-helpers';

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
    applyMiddleware(
        thunkMiddleware,
        sagaMiddleware
        // unauthorizedMiddleware,
    ),
    persistState(['user', 'app'], {
        key: 'datapool',
        deserialize: data => {
            let parsed = JSON.parse(data);

            if (parsed === null) {
                return {};
            }

            const now = new Date().getTime() / 1000;

            let language = parsed.app.language;

            if (now > parsed.user.token_expire) {
                parsed.user = DEFAULT_STATE;
            } else {
                // se esiste un utente valido recupero la lingua dal suo locale
                language = getShortCodeFromLocale(parsed.user.locale);
            }

            return {
                user: UserRecord(parsed.user),
                app: AppRecord({
                    language
                })
            };
        }
        // deserialize: data => {
        //     return Immutable(JSON.parse(data));
        // }
    })
    // batchedSubscribe(batchedUpdates)
);

export default function configureStore(preloadedState = {}) {
    const store = createStore(reducer, preloadedState, enhancer);

    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);

    return store;
}
