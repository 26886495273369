import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import Swal from 'sweetalert2';
import Highlighter from 'react-highlight-words';
import {
    FaCogs,
    // FaEye,
    FaEdit,
    FaTrash,
    FaCopy
    // FaDownload
} from 'react-icons/fa';

import LimitText from './utils/LimitText';
import Loader from './utils/Loader';
import TableButton from './utils/TableButton';
import SortableTableHeader from './utils/SortableTableHeader';
import Paginator from './Paginator';
import EditCollectionModal from '../containers/EditCollectionModal';

export class CollectionsList extends Component {
    state = {
        showEditCollectionModal: false,
        currentCollectionId: null
    };

    componentDidMount() {
        this.props.fetchCollections();
    }

    showEditCollectionModal = () => {
        this.setState({
            showEditCollectionModal: true
        });
    };

    editCollection = id => {
        this.setState({
            currentCollectionId: id,
            showEditCollectionModal: true
        });
    };

    handleCloseModal = () => {
        this.setState({
            currentCollectionId: null,
            showEditCollectionModal: false
        });
    };

    handleDeleteCollection = id => {
        const { i18n } = this.props;

        Swal.fire({
            type: 'warning',
            text: i18n._('confirm:collection:delete'),
            showCancelButton: true,
            confirmButtonText: i18n._('yes'),
            cancelButtonText: i18n._('no')
        }).then(res => {
            if (res.value === true) {
                this.props.deleteCollection(id);
            }
        });
    };

    handleDupeCollection = id => {
        const { i18n } = this.props;

        const collection = this.getCollectionById(id);

        let collectionName = `<strong>${collection.descrizione}</strong>`;

        if (collection.codice !== null) {
            collectionName = `<strong>${collection.codice}</strong> - ${collectionName}`;
        }

        Swal.fire({
            type: 'warning',
            html: i18n._('collection:confirm:dupe', { name: collectionName }),
            showCancelButton: true,
            confirmButtonText: i18n._('yes'),
            cancelButtonText: i18n._('no')
        }).then(res => {
            if (res.value === true) {
                this.props.dupeCollection(id);
            }
        });
    };

    getCollectionById(id) {
        const result = this.props.collections.filter(c => c.id === id);

        if (result) {
            return result.first();
        }

        return result;
    }

    getCollectionsToDisplay() {
        const { collections, collectionEngagedId } = this.props;

        if (collectionEngagedId === null) {
            return collections;
        }

        const collectionEngaged = this.getCollectionById(collectionEngagedId);

        // Se non trovo la collezione che mi aspetto ritorno la lista completa per evitare errori
        if (!collectionEngaged) {
            return collections;
        }

        return [collectionEngaged];
    }

    handleUpdateFilter = e => {
        // console.warn(e);
        // console.warn(e.target.name);

        this.props.updateCollectionFilter(e.target.name, e.target.value);
    };

    render() {
        const { showEditCollectionModal, currentCollectionId } = this.state;

        const {
            isFetching,
            toggleEngageCollection,
            // toggleShownCollection,
            collectionEngagedId,
            // collectionShown,
            sorting,
            sortBy,
            pages,
            currentPage,
            filters,
            total,
            total_unfiltered,
            i18n
        } = this.props;

        if (isFetching) {
            return <Loader />;
        }

        // console.warn(this.props);

        return (
            <div className="columns">
                {showEditCollectionModal && (
                    <EditCollectionModal
                        collectionId={currentCollectionId}
                        onClose={this.handleCloseModal}
                    />
                )}
                <table className="table table-dense">
                    <thead>
                        <tr>
                            <SortableTableHeader
                                headerStyle={{ width: '25%' }}
                                attr="codice"
                                disabled={collectionEngagedId !== null}
                                onClick={sortBy}
                                sorting={sorting}
                            >
                                <Trans id="code" />
                            </SortableTableHeader>
                            <SortableTableHeader
                                attr="descrizione"
                                disabled={collectionEngagedId !== null}
                                onClick={sortBy}
                                sorting={sorting}
                            >
                                <Trans id="collection:description" />
                            </SortableTableHeader>
                            <th style={{ width: '200px' }}>
                                {collectionEngagedId === null && (
                                    <Paginator
                                        pages={pages}
                                        currentPage={currentPage}
                                        onPageChange={this.props.changePage}
                                        containerClassName="pagination pagination-compact"
                                    />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    className="form-input input-md"
                                    value={filters.code}
                                    name="code"
                                    onChange={this.handleUpdateFilter}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-input input-md"
                                    value={filters.description}
                                    name="description"
                                    onChange={this.handleUpdateFilter}
                                />
                            </td>
                            <td>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={this.showEditCollectionModal}
                                >
                                    <i className="icon icon-plus" />
                                </button>
                            </td>
                        </tr>
                        {total === 0 && total_unfiltered > 0 && (
                            <tr>
                                <td colSpan="99">
                                    <div className="empty empty-short">
                                        <div className="empty-icon">
                                            <i className="icon icon-search" />
                                        </div>
                                        <p className="empty-title h5">
                                            <Trans id="attention" />
                                        </p>
                                        <p className="empty-subtitle">
                                            <Trans id="collection:not:found" />
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {this.getCollectionsToDisplay().map(c => (
                            <tr key={c.id}>
                                <td>
                                    <LimitText
                                        text={c.codice}
                                        limit={30}
                                        highlight={filters.code}
                                    />
                                </td>
                                <td>
                                    <span
                                        className={c.note !== null ? 'tooltip' : ''}
                                        data-tooltip={c.note}
                                    >
                                        <Highlighter
                                            searchWords={[filters.description]}
                                            textToHighlight={c.descrizione}
                                        />
                                    </span>{' '}
                                    <span className="label label-rounded label-primary">
                                        {c.articoli_number}
                                    </span>
                                </td>
                                <td colSpan={2}>
                                    <TableButton
                                        tooltip={i18n._('collection:engage')}
                                        active={collectionEngagedId === c.id}
                                    >
                                        <FaCogs onClick={() => toggleEngageCollection(c.id)} />
                                    </TableButton>
                                    {/* <TableButton
                                        tooltip={i18n._('collection:view')}
                                        active={collectionShown === c.id}
                                    >
                                        <FaEye onClick={() => toggleShownCollection(c.id)} />
                                    </TableButton> */}
                                    <TableButton tooltip={i18n._('collection:edit')}>
                                        <FaEdit onClick={() => this.editCollection(c.id)} />
                                    </TableButton>
                                    <TableButton tooltip={i18n._('collection:delete')}>
                                        <FaTrash
                                            onClick={() => this.handleDeleteCollection(c.id)}
                                        />
                                    </TableButton>
                                    <TableButton tooltip={i18n._('collection:dupe')}>
                                        <FaCopy onClick={() => this.handleDupeCollection(c.id)} />
                                    </TableButton>
                                    {/* <TableButton tooltip="Esporta la collezione">
                                        <FaDownload />
                                    </TableButton> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

CollectionsList.propTypes = {
    isFetching: PropTypes.bool,
    collections: PropTypes.object,
    collectionEngagedId: PropTypes.string,
    collectionShown: PropTypes.string,
    toggleEngageCollection: PropTypes.func
};

export default withI18n()(CollectionsList);
