import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { withI18n, Trans } from '@lingui/react';
import trim from 'lodash/trim';
import words from 'lodash/words';
import cx from 'classnames';

import { actions } from '../actions/filtersActions';
import * as constants from '../constants';

// const errors = {
//     tooShort: 'Le parole da ricercare devono essere di almeno 2 caratteri',
//     invalidChars:
//         'Il testo inserito contiene caratteri speciali, sostituirli con uno spazio e ripetere la ricerca'
// };

export class HeaderSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: props.fullTextFilter.value,
            shouldDisplayErrorTooltip: false
            // errorType: null
        };
    }

    componentDidMount() {
        if (this.searchField) {
            this.searchField.focus();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.fullTextFilter.value !== this.props.fullTextFilter.value
        ) {
            this.setState({
                inputValue: this.props.fullTextFilter.value
            });
        }
    }

    isInputInvalid(value) {
        if (value === '') {
            return false;
        }

        // Separo per tutto cio che non è \w ([_a-zA-Z0-9]) o -
        const w = words(value, /[-\w]+/g);

        if (w.length === 0) {
            return true;
        }

        return w.reduce((res, w) => {
            if (res) {
                return res;
            }

            if (trim(w).length < 2) {
                return true;
            }

            return false;
        }, false);
    }

    hasInputSpecialCharts(value) {
        // la stringa è valida solo se contiene [a-zA-Z0-9_] o whitespace
        const re = /[^\w\s]+/;

        if (re.test(trim(value))) {
            return true;
        }
    }

    // getErrorText() {
    //     if (this.state.errorType === null) {
    //         return '';
    //     }

    //     return errors[this.state.errorType];
    // }

    handleInputChange = e => {
        const newState = {
            inputValue: e.target.value
        };

        const isInputInvalid = this.isInputInvalid(e.target.value);

        if (isInputInvalid === false) {
            newState.shouldDisplayErrorTooltip = false;
            // newState.errorType = null;
        } /* else {
            newState.errorType = isInputInvalid;
        }*/

        this.setState(newState);
    };

    handleInputKeyPress = e => {
        if (e.key === 'Enter') {
            setImmediate(() => {
                this.handleSearch();
            });
        }
    };

    handleSearch = () => {
        const { inputValue } = this.state;

        const isInputInvalid = this.isInputInvalid(inputValue);

        if (isInputInvalid === false) {
            this.setState({
                shouldDisplayErrorTooltip: false
                // errorType: null
            });

            if (this.hasInputSpecialCharts(inputValue)) {
                this.showConfirmDialog();

                return false;
            }

            this.doSearch();
        } else {
            this.setState({
                shouldDisplayErrorTooltip: true
                // errorType: isInputInvalid
            });
        }
    };

    showConfirmDialog() {
        const { i18n } = this.props;

        Swal.fire({
            type: 'warning',
            text: i18n._('confirm:search:text:with:special:characters'),
            showCancelButton: true,
            confirmButtonText: i18n._('yes'),
            cancelButtonText: i18n._('no')
        }).then(res => {
            if (res.value === true) {
                this.doSearch();
            } else {
                this.searchField.focus();
            }
        });
    }

    doSearch() {
        this.props.dispatch(
            actions.setFilterValue(
                constants.FULL_TEXT_FILTER,
                this.state.inputValue
            )
        );
    }

    render() {
        const { i18n } = this.props;
        const { inputValue, shouldDisplayErrorTooltip } = this.state;

        const inputGroupClass = cx(
            'input-group',
            'tooltip-bottom',
            'tooltip-bottom-low',
            'tooltip-danger',
            {
                tooltip: shouldDisplayErrorTooltip
            }
        );

        return (
            <div className="navbar-center navbar-search-section">
                <div className="has-icon-right navbar-search-holder">
                    <div
                        className={inputGroupClass}
                        data-tooltip={i18n._(
                            'The words to search must be at least 2 characters'
                        )}
                    >
                        <input
                            type="text"
                            className="form-input full-text-search-input"
                            value={inputValue}
                            onChange={this.handleInputChange}
                            onKeyPress={this.handleInputKeyPress}
                            placeholder={i18n._('global:search:field:helper')}
                            ref={ref => (this.searchField = ref)}
                        />
                        <button
                            className="btn btn-secondary input-group-btn"
                            // disabled={this.isInputInvalid(inputValue)}
                            onClick={this.handleSearch}
                        >
                            <i className="icon icon-search" />
                        </button>
                    </div>
                </div>
                <button
                    className="btn tooltip tooltip-bottom"
                    data-tooltip={i18n._('Reset all selected parameters')}
                    onClick={() => window.location.reload()}
                >
                    <i className="icon icon-refresh" /> <Trans id="reset" />
                </button>
            </div>
        );
    }
}

const HeaderSearchIntl = withI18n()(HeaderSearch);

export default HeaderSearchIntl;
