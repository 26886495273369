import { all, call, put, select, takeEvery } from 'redux-saga/effects';
// import { delay } from 'redux-saga';

import { actionTypes, actions, setFixedBrand } from '../actions/appActions';
import {
    setFilterOptions,
    enableFilters
    // changeBrandFilterValue
} from '../actions/filtersActions';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { setProductClassOptions } from '../actions/productClassesActions';
import { setTree, initProductTreeRoots } from '../actions/sectorsActions';
import { removeSplashScreenLoader } from '../helpers';
// import { setChosenCategories } from '../actions/itemDataCategoriesActions';

import {
    fetchBrandFilter,
    fetchClassificationTree,
    fetchEtimClassFilter,
    // getProductInfoMap,
    getAllProductProperties,
    fetchAnagrafica,
    fetchProductTreeRootsFilter,
    getUserGroups
} from '../api';
import * as constants from '../constants';
import { fetchItems } from '../actions/itemsActions';
import { brandDependentFilters } from './filtersSaga';

export function* bootstrapApp() {
    try {
        const user = yield select(userSelectors.getUser);

        yield put(actions.bootstrapAppStart());

        const productTreeRootsRes = yield call(fetchProductTreeRootsFilter);

        yield put(initProductTreeRoots(productTreeRootsRes.data));

        const apiPayload = yield select(filtersSelectors.getApiPayload);

        const calls = [
            call(fetchBrandFilter, apiPayload),
            call(fetchClassificationTree, apiPayload),
            call(fetchEtimClassFilter, apiPayload),
            // call(getProductInfoMap),
            call(getAllProductProperties),
            call(fetchAnagrafica)
        ];

        if (user.ruolo === 'admin') {
            calls.push(call(getUserGroups));
        }

        const [
            brandsRes,
            treeRes,
            etimClassRes,
            // productInfoMapRes,
            productPropertiesRes,
            anagraficaRes,
            userGroupsRes
        ] = yield all(calls);

        yield put(setFilterOptions(constants.BRAND_FILTER, brandsRes.data));
        // yield put(actions.setProductInfoMap(productInfoMapRes.data));

        yield put(actions.setProductProperties(productPropertiesRes.data));

        yield put(setProductClassOptions(etimClassRes.data));
        yield put(setTree(treeRes.data));
        yield put(actions.setAppName(anagraficaRes.data.datapool_name));

        if (user.ruolo === 'admin') {
            yield put(actions.setUserGroups(userGroupsRes.data));
        }

        if (brandsRes.data.length === 1) {
            const brand = brandsRes.data[0];

            yield put(
                setFixedBrand({
                    label: brand.nome,
                    value: brand.id
                })
            );

            yield put(fetchItems({ bootstrap: true }));

            yield put(enableFilters(brandDependentFilters));
        } else {
            yield put(actions.bootstrapAppSuccess());
        }
    } catch (err) {
        // TODO: gestire messaggio di errore
        yield put(actions.bootstrapAppFail('Unable to contact remote server'));
        console.error(err);
    }
}

function hideLoadingSplashScreen() {
    removeSplashScreenLoader();
}

export default [
    takeEvery(actionTypes.BOOTSTRAP_APP, bootstrapApp),
    takeEvery(actionTypes.HIDE_LOADING_SPLASH_SCREEN, hideLoadingSplashScreen)
];
