import { call, put, takeEvery, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toast } from 'react-toastify';
// import { FORM_ERROR } from 'final-form';

import { actionTypes, actions } from '../actions/collectionsActions';
import { actions as appActions } from '../actions/appActions';
import { actions as itemsActions } from '../actions/itemsActions';
import {
    fetchCollections as fetchCollectionsApi /*, updateCollection*/,
    addItemsToCollection,
    removeItemsFromCollection,
    dupeCollection,
    deleteCollection,
    addFilteredItemsToCollection,
    removeFilteredItemsFromCollection
} from '../api';
import { selectors } from '../reducers/collectionsReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { getMessageFromI18nCatalog } from '../intl-helpers';

export function* fetchCollectionsSaga(action) {
    try {
        yield put(showLoading('collectionsFetch'));

        yield put(actions.fetchCollectionsStart());

        const user = yield select(userSelectors.getUser);

        const res = yield call(fetchCollectionsApi, user.id);

        yield put(actions.fetchCollectionsSuccess(res));

        yield put(hideLoading('collectionsFetch'));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);

        yield put(hideLoading('collectionsFetch'));
        yield put(actions.fetchCollectionsFail(err));
    }
}

// function* changePageSaga() {
//     yield put(actions.fetchCollections());
// }

export function* toggleItemFromEngagedCollectionSaga(action) {
    const engagedCollectionId = yield select(selectors.getEngagedCollection);
    const itemsCollections = yield select(selectors.getItemsCollections);

    const itemId = action.payload;

    // console.warn(itemsCollections.get(itemId), engagedCollectionId);
    yield put(actions.toggleItemFromCollection(engagedCollectionId, itemId));

    const user = yield select(userSelectors.getUser);

    if (
        itemsCollections.get(itemId) &&
        itemsCollections.get(itemId).includes(engagedCollectionId)
    ) {
        yield call(removeItemsFromCollection, user.id, engagedCollectionId, itemId);
    } else {
        yield call(addItemsToCollection, user.id, engagedCollectionId, itemId);
    }

    // TODO: restituire qualche feedback all'utente in caso di successo o errore
}

function* dupeCollectionSaga(action) {
    // const collectionToDupe = yield select(selectors.getItem, action.payload);

    // console.warn(collectionToDupe);

    // const payload = {
    //     descrizione: `Copia della collezione ${collectionToDupe.descrizione}`
    // };

    const language = yield select(appSelectors.getLanguage);

    try {
        const user = yield select(userSelectors.getUser);

        yield call(dupeCollection, user.id, action.payload);

        toast(getMessageFromI18nCatalog(language, 'collection:duplicate:success'), {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS
        });

        yield put(actions.fetchCollections());
    } catch (e) {
        // TODO: gestire meglio errori
        toast(getMessageFromI18nCatalog(language, 'error:unexpected'), {
            position: 'bottom-right',
            type: toast.TYPE.ERROR
        });
    }
}

export function* toggleEngageCollectionSaga() {
    const collectionEngaged = yield select(selectors.getEngagedCollection);

    // Sia che ingaggi o che disingaggi riporto l'applicazione allo stato iniziale
    yield put(appActions.restoreBootstrappedState());

    if (collectionEngaged) {
        yield put(actions.setShownCollection(collectionEngaged));
        yield put(itemsActions.fetchCollectionItems());
    } else {
        yield put(actions.resetShownCollection());
        yield put(itemsActions.resetItems());
    }
}

export function* toggleShownCollectionSaga(action) {
    const shownCollection = yield select(selectors.getShownCollection);

    if (shownCollection) {
        // const collectionEngaged = yield select(selectors.getEngagedCollection);
        yield put(appActions.restoreBootstrappedState());
        yield put(itemsActions.fetchCollectionItems());
    } else {
        // TODO: ???
    }
}

export function* addAllItemsToCollectionSaga(action) {
    const collectionEngagedId = yield select(selectors.getEngagedCollection);

    const apiPayload = yield select(filtersSelectors.getApiPayload);

    apiPayload.collezione = collectionEngagedId;

    const user = yield select(userSelectors.getUser);

    const res = yield call(addFilteredItemsToCollection, user.id, collectionEngagedId, apiPayload);

    yield put(
        actions.setItemsCollections({
            data: res.data.map(record => {
                return {
                    articolo_id: record.articolo_id,
                    collezione_ids: [collectionEngagedId]
                };
            })
        })
    );

    yield put(actions.updateCollectionItemsNumber(collectionEngagedId, res.data.length));
}

function* removePagedItemsFromCollectionSaga(action) {
    const collectionEngagedId = yield select(selectors.getEngagedCollection);

    let apiPayload = yield select(filtersSelectors.getApiPayload);

    apiPayload.collezione = collectionEngagedId;

    const user = yield select(userSelectors.getUser);

    const language = yield select(appSelectors.getLanguage);

    let res;

    try {
        res = yield call(
            removeFilteredItemsFromCollection,
            user.id,
            collectionEngagedId,
            apiPayload
        );
    } catch (e) {
        toast(getMessageFromI18nCatalog(language, 'error:unexpected'), {
            position: 'bottom-right',
            type: toast.TYPE.ERROR
        });

        return false;
    }

    toast(getMessageFromI18nCatalog(language, 'collection:articles:removed'), {
        position: 'bottom-right',
        type: toast.TYPE.SUCCESS
    });

    const currentCollectionItemsNumber = yield select(
        selectors.getCollectionItemsNumber,
        collectionEngagedId
    );

    yield put(
        actions.updateCollectionItemsNumber(
            collectionEngagedId,
            currentCollectionItemsNumber - res.data.deleted
        )
    );

    yield put(actions.resetItemsCollections());

    yield put(itemsActions.changePage(0));

    yield put(itemsActions.fetchCollectionItems());
}

function* deleteCollectionSaga(action) {
    const language = yield select(appSelectors.getLanguage);

    try {
        const user = yield select(userSelectors.getUser);

        yield call(deleteCollection, user.id, action.payload);

        const collectionEngagedId = yield select(selectors.getEngagedCollection);

        const collectionShownId = yield select(selectors.getShownCollection);

        yield put(actions.deleteCollectionSuccess(action.payload));

        // Necessario???
        yield put(actions.fetchCollections());

        if (collectionEngagedId === action.payload || collectionShownId === action.payload) {
            yield put(itemsActions.resetItems());
        }

        toast(getMessageFromI18nCatalog(language, 'collection:delete:success'), {
            position: 'bottom-right',
            type: toast.TYPE.ERROR
        });
    } catch (e) {
        toast(getMessageFromI18nCatalog(language, 'error:unexpected'), {
            position: 'bottom-right',
            type: toast.TYPE.ERROR
        });
    }
}

// function* updateCollectionSaga(action) {
//     const { payload } = action;

//     // const xhr = this.props.collection.id
//     //     ? updateCollection(this.props.collection.id, payload)
//     //     : createCollection(payload);

//     try {
//         const res = yield call(updateCollection, payload.id, payload);

//         yield put(actions.updateCollectionSuccess(res));
//     } catch (err) {
//         let errorMessage = payload.id
//             ? 'Si è verificato un errore imprevisto durante la modifica della collezione.'
//             : 'Si è verificato un errore imprevisto durante la creazione della collezione.';

//         if (err.response) {
//             // console.log(err.response.data);
//             // console.log(err.response.status);
//             if (err.response.status === 412) {
//                 errorMessage = 'Compilare tutti i campi richiesti.';
//             }
//         } else if (err.request) {
//             errorMessage = 'Impossibile contattare il server remoto.';
//         }

//         yield put(actions.updateCollectionFail({
//             [FORM_ERROR]: errorMessage
//         }));
//     }

//     const msg = payload.id
//         ? 'Collezione modificata corretamente!'
//         : 'Collezione creata correttamente!';

//     toast(msg, {
//         position: 'bottom-right',
//         type: toast.TYPE.SUCCESS
//     });
// }

export default [
    takeEvery(actionTypes.FETCH_COLLECTIONS, fetchCollectionsSaga),
    // takeEvery(actionTypes.CHANGE_COLLECTIONS_PAGE, changePageSaga),
    takeEvery(actionTypes.TOGGLE_ITEM_FROM_ENGAGED_COLLECTION, toggleItemFromEngagedCollectionSaga),
    takeEvery(actionTypes.DUPE_COLLECTION, dupeCollectionSaga),
    takeEvery(actionTypes.TOGGLE_ENGAGE_COLLECTION, toggleEngageCollectionSaga),
    takeEvery(actionTypes.TOGGLE_SHOWN_COLLECTION, toggleShownCollectionSaga),
    takeEvery(actionTypes.ADD_ALL_ITEMS_TO_COLLECTION, addAllItemsToCollectionSaga),
    takeEvery(actionTypes.REMOVE_PAGED_ITEMS_FROM_COLLECTION, removePagedItemsFromCollectionSaga),
    takeEvery(actionTypes.DELETE_COLLECTION, deleteCollectionSaga)
    // takeEvery(actionTypes.UPDATE_COLLECTION, updateCollectionSaga)
];
