import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
// import _ from 'lodash';

import Loader from './utils/Loader';
import ErrorBoundary from './utils/ErrorBoundary';
import { actions as productClassesActions } from '../actions/productClassesActions';
import ProductClassAttributeFilters from './ProductClassAttributeFilters';
import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import ProductClassFilterActive from './ProductClassFilterActive';
import { getOptionLabelLocalized } from '../intl-helpers';
// import noPhotoImg from '../images/no-photo.png';

export class ProductClassFilter extends Component {
    state = {
        showFilters: true,
        lastSearchHash: null
    };

    toggleFilters = () => {
        this.setState(prevState => ({
            showFilters: !prevState.showFilters
        }));
    };

    // toggleImage = () => {
    //     this.setState(prevState => ({
    //         showImage: !prevState.showImage
    //     }));
    // };

    handleChangeBooleanField = (key, value) => {
        this.props.onSetProductClassAttributeLogic(key, value);
    };

    handleChangeNumberField = (key, values) => {
        this.props.onSetProductClassAttributeNumeric(key, values);
    };

    // handleFilterClick = () => {
    //     this.props.fetchItems();

    //     this.setState({
    //         lastSearchHash: this.props.activeFilters.hashCode()
    //     });
    // };

    handleResetClick = () => {
        this.props.resetProductClassAttributeAll();

        // this.setState({
        //     lastSearchHash: null
        // });
    };

    // isDisabledFilterBtn() {
    //     if (
    //         this.state.lastSearchHash === null &&
    //         this.props.activeFilters.count() === 0
    //     ) {
    //         return true;
    //     }

    //     return (
    //         this.state.lastSearchHash === this.props.activeFilters.hashCode()
    //     );
    // }

    isDisabledResetBtn() {
        // if (this.state.lastSearchHash === null) {
        //     return true;
        // }

        return this.props.activeFilters.count() === 0;
    }

    render() {
        const {
            i18n,
            isFetching,
            productClassFilter,
            activeFilters,
            attributes,
            onCheckboxToggle,
            onAttributeResetClick,
            fetchProductClassAttributeData,
            validFilterOptions,
            language
        } = this.props;

        const { showFilters /*, showImage*/ } = this.state;

        const hasAttributes = attributes.size > 0;

        const active = productClassFilter.value !== null;
        const showLoader = active && isFetching;

        return (
            <div className="bg-gray p-2 columns" style={{ paddingTop: '20px' }}>
                {/* <div className="column col-2 text-right">
                    <div className="text-uppercase text-primary mt-1">
                        <Trans id="Product class" />
                    </div>
                </div> */}
                <div className="column col-8">
                    <div className="columns">
                        <div className="col-12">
                            <DropdownListFilterWrapper
                                filter={productClassFilter}
                                validFilterOptions={validFilterOptions}
                                placeholder={i18n._('filter:product:class')}
                                itemToString={getOptionLabelLocalized(language)}
                                onChange={item =>
                                    this.props.dispatch(
                                        productClassesActions.setProductClassValue(item)
                                    )
                                }
                            />
                        </div>
                        <div className="col-12">
                            {!showLoader && active && (
                                <ProductClassFilterActive
                                    activeFilters={activeFilters}
                                    language={language}
                                    attributes={attributes}
                                    showHeader={active}
                                    onAttributeResetClick={onAttributeResetClick}
                                    onCheckboxToggle={onCheckboxToggle}
                                    onChangeBooleanField={this.handleChangeBooleanField}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="column col-2 text-right">
                    <div className="form-group">
                        {hasAttributes && (
                            <label className="form-switch" style={{ display: 'inline-block' }}>
                                <input
                                    disabled={!active}
                                    type="checkbox"
                                    checked={showFilters}
                                    onChange={this.toggleFilters}
                                />
                                <i className="form-icon" /> <Trans id="Show filters" />
                            </label>
                        )}
                        {/* <label className="form-switch">
                            <input
                                disabled={!active}
                                type="checkbox"
                                checked={showImage}
                                onChange={this.toggleImage}
                            />
                            <i className="form-icon" /> Mostra
                            immagine
                        </label> */}
                    </div>
                    {/* {this.state.showImage &&
                        !showLoader &&
                        active && (
                            <img
                                src={noPhotoImg}
                                className="img-responsive float-right"
                                alt="Classe prodotto"
                            />
                        )}*/}
                </div>
                {!showLoader && active && (
                    <Fragment>
                        <div className="column col-4" />
                        <div className="column col-4 text-center" style={{ marginBottom: '1rem' }}>
                            {hasAttributes && (
                                <Fragment>
                                    {/* <button
                                            className="btn btn-sm btn-primary btn-rounded"
                                            disabled={this.isDisabledFilterBtn()}
                                            onClick={this.handleFilterClick}
                                        >
                                            <Trans id="apply:filter" />
                                        </button>{' '} */}
                                    <button
                                        className="btn btn-sm btn-error btn-rounded"
                                        disabled={this.isDisabledResetBtn()}
                                        onClick={this.handleResetClick}
                                    >
                                        <Trans id="Reset filters" />
                                    </button>
                                </Fragment>
                            )}
                            {!hasAttributes && (
                                <div className="empty empty-short">
                                    <p className="empty-title h5">
                                        <Trans id="No feature present" />
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="column col-4" />
                    </Fragment>
                )}
                {showLoader && (
                    <div className="columm col-12 text-center">
                        <Loader height={100} />
                    </div>
                )}
                <ErrorBoundary>
                    {this.state.showFilters && (
                        <ProductClassAttributeFilters
                            etimClassId={
                                productClassFilter.value ? productClassFilter.value.value : null
                            }
                            activeFilters={activeFilters}
                            attributes={attributes}
                            language={language}
                            onCheckboxToggle={onCheckboxToggle}
                            onAttributeResetClick={onAttributeResetClick}
                            onChangeBooleanField={this.handleChangeBooleanField}
                            onChangeNumberField={this.handleChangeNumberField}
                            onFetchProductClassAttributeData={fetchProductClassAttributeData}
                            mainLoaderShowing={showLoader}
                        />
                    )}
                </ErrorBoundary>
            </div>
        );
    }
}

ProductClassFilter.propTypes = {
    attributes: PropTypes.object,
    activeFilters: PropTypes.object,
    i18n: PropTypes.object,
    language: PropTypes.string,
    productClassFilter: PropTypes.object,
    onAttributeResetClick: PropTypes.func.isRequired,
    onCheckboxToggle: PropTypes.func.isRequired,
    resetProductClassAttributeAll: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchProductClassAttributeData: PropTypes.func.isRequired,
    validFilterOptions: PropTypes.object
};

export default withI18n()(ProductClassFilter);
