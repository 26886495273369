import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import ArticleDetailsBody from './ArticleDetailsBody';
import ScrollToTopButton from './utils/ScrollToTopButton';
import scrollPosHandler from './utils/ScrollPosHandler';
import Loader from './utils/Loader';
import {
    /*fetchItemDetails,*/ fetchItemDetails,
    getDescrizioneMarcaFromArticolo
} from '../helpers';
import { getLocaleFromLanguageCode } from '../intl-helpers';

export class ArticleDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details,
            etimClassDetails: props.etimClassDetails,
            isFetching: props.details === null
        };

        if (props.details === null) {
            this.fetchMissingData();
        }
    }

    componentDidMount() {
        this.props.onMount();
    }

    async fetchMissingData() {
        const articleDetails = await fetchItemDetails(this.props.article.id);

        // console.log(articleDetails);

        this.setState({
            details: articleDetails,
            etimClassDetails: articleDetails.etim,
            isFetching: false
        });
    }

    getMarcaArticolo() {
        return getDescrizioneMarcaFromArticolo(this.state.details, this.getLocale());
    }

    getCodiceArticolo() {
        return get(this.state.details, 'identificazione.codice_articolo.value');
    }

    getDescrizioneArticolo() {
        return get(
            this.state.details,
            `identificazione.descrizione_articolo.value.${this.getLocale()}`
        );
    }

    getLocale() {
        return getLocaleFromLanguageCode(this.props.language);
    }

    renderModal() {
        const {
            article,
            onClose,
            isPreview,
            isModal,
            language,
            // productProperties,
            initialCategories,
            scrollPos
        } = this.props;

        const { details, etimClassDetails } = this.state;

        return (
            <Fragment>
                <div className="modal-header">
                    <div className="modal-title h5">
                        {this.getMarcaArticolo()} - {this.getCodiceArticolo()} -{' '}
                        <span style={{ fontWeight: 200 }}>{this.getDescrizioneArticolo()}</span>
                        {isPreview === false && isModal && (
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        )}
                    </div>
                </div>
                <div className="modal-body" id="article-detail-modal">
                    <div className="content">
                        <ArticleDetailsBody
                            article={article}
                            details={details}
                            isPreview={isPreview}
                            initialCategories={initialCategories}
                            language={language}
                            // productProperties={productProperties}
                            etimClassDetails={etimClassDetails}
                            scrollContainer="article-detail-modal"
                        />
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        padding: '0.4rem',
                        minHeight: '2.8rem'
                    }}
                >
                    {scrollPos > 300 && (
                        <div>
                            <ScrollToTopButton scrollContainer={'article-detail-modal'} />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }

    renderLoader() {
        return (
            <div className="modal-body" id="article-detail-modal">
                <Loader />
            </div>
        );
    }

    render() {
        const { isPreview, isModal } = this.props;
        const { isFetching } = this.state;

        return (
            <div className="container">
                <div
                    className={isPreview || isModal === false ? '' : 'modal modal-wide active'}
                    id="article-detail-modal-id"
                >
                    <span className="modal-overlay" aria-label="Close" />
                    <div className={isPreview || isModal === false ? '' : 'modal-container'}>
                        {isFetching ? this.renderLoader() : this.renderModal()}
                    </div>
                </div>
            </div>
        );
    }
}

ArticleDetailsModal.propTypes = {
    details: PropTypes.object,
    etimClassDetails: PropTypes.object,
    initialCategories: PropTypes.array,
    isPreview: PropTypes.bool,
    onMount: PropTypes.func,
    language: PropTypes.string
};

ArticleDetailsModal.defaultProps = {
    details: null,
    etimClassDetails: null,
    initialCategories: [],
    isPreview: false,
    isModal: true,
    onMount: () => {}
};

export default scrollPosHandler('article-detail-modal')(ArticleDetailsModal);
