import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import {
//     AccordionItem,
//     AccordionItemTitle,
//     AccordionItemBody
// } from 'react-accessible-accordion';
import { withI18n, Trans } from '@lingui/react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import cx from 'classnames';

import * as c from '../constants';
import DropdownList from './utils/DropdownList';
import ItemDataCategoryTable from './ItemDataCategoryTable';

function getUniqueExts(attributes) {
    return uniq(attributes.map(a => a.ext));
}

export class ItemDataCategoryBody extends Component {
    state = {
        showPreview: false,
        selectedItems: []
    };

    handleDownload = e => {
        e.stopPropagation();

        // console.warn('HANDLE DOWNLOAD');
    };

    togglePreview = () => {
        this.setState(prevState => {
            return {
                showPreview: !prevState.showPreview
            };
        });
    };

    // componentDidUpdate() {
    //     console.warn(this.state.selectedItems);
    // }

    toggleItem = key => {
        const { selectedItems } = this.state;

        if (includes(selectedItems, key)) {
            this.setState({
                selectedItems: without(selectedItems, key)
            });

            return;
        }

        this.setState({
            selectedItems: [].concat(selectedItems, key)
        });
    };

    shouldDisplayFilters() {
        const { category } = this.props;

        return (
            category.type === c.SECTION_TYPE_FILES ||
            category.type === c.SECTION_TYPE_IMAGES ||
            category.key === c.SECTION_KEY_CORRELATI
        );
    }

    shouldDisplayPreviewBtn() {
        return this.props.category.type === c.SECTION_TYPE_IMAGES;
    }

    shouldDisplayExtFilter() {
        if (!this.shouldDisplayPreviewBtn()) {
            return false;
        }

        const exts = getUniqueExts(this.props.attributes);

        if (exts.length > 1) {
            return true;
        }

        return false;
    }

    render() {
        const {
            attributes,
            category,
            filterOptions,
            selectedFilter,
            extFilter,
            extOptions,
            // isOpen,
            // onClick,
            onFileClick,
            onFilterChange,
            onExtFilterChange,
            i18n
        } = this.props;

        // console.warn(selectedFilter);

        const renderCols =
            this.shouldDisplayFilters() ||
            this.shouldDisplayPreviewBtn() ||
            this.shouldDisplayExtFilter();

        const titleColClass = renderCols
            ? this.shouldDisplayExtFilter()
                ? 'col-3'
                : 'col-4'
            : 'col-12';

        // 96525458

        // console.warn(filterOptions);

        const shouldDisplayPreviewSwitch = window.innerWidth >= 640;

        return (
            <div className="item-data-categories-element">
                <div
                    className={cx('accordion-article-title', 'container', {
                        '--has-filters': renderCols
                    })}
                    id={`accordion-article-section-${category.key}`}
                >
                    <div className="columns">
                        {renderCols && (
                            <div className="column col-4 text-left">
                                {this.shouldDisplayFilters() && (
                                    <DropdownList
                                        className="compact"
                                        clearable={false}
                                        useVirtualList={false}
                                        wrapperStyle={{
                                            width: '90%',
                                            marginTop: '2px'
                                        }}
                                        onWrapperClick={e => e.stopPropagation()}
                                        items={filterOptions}
                                        selectedItem={selectedFilter}
                                        onChange={onFilterChange}
                                    />
                                )}
                            </div>
                        )}
                        {this.shouldDisplayExtFilter() && (
                            <div className="column col-1 text-left">
                                <DropdownList
                                    className="compact"
                                    clearable={false}
                                    useVirtualList={false}
                                    onWrapperClick={e => e.stopPropagation()}
                                    items={extOptions}
                                    selectedItem={extFilter}
                                    onChange={onExtFilterChange}
                                />
                            </div>
                        )}
                        <div className={`column ${titleColClass} text-center`}>
                            <span className="text-uppercase text-bold text-large mx-2">
                                <Trans id={category.label} />
                            </span>{' '}
                        </div>
                        {renderCols && shouldDisplayPreviewSwitch && (
                            <div className="column col-4 text-right">
                                {this.shouldDisplayPreviewBtn() && (
                                    <span className="form-group">
                                        <label
                                            className="form-switch"
                                            style={{ display: 'inline-block' }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={this.state.showPreview}
                                                onChange={this.togglePreview}
                                            />
                                            <i className="form-icon" /> {i18n._('Show preview')}
                                        </label>
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="accordion-article-body">
                    <ItemDataCategoryTable
                        attributes={attributes}
                        type={category.type}
                        category={category}
                        onClick={i => {
                            onFileClick(i);
                        }}
                        showPreview={this.state.showPreview}
                        selected={this.state.selectedItems}
                        toggleItem={this.toggleItem}
                        selectedFilter={selectedFilter.value}
                        extFilter={extFilter.value}
                    />
                </div>
            </div>
        );
    }
}

ItemDataCategoryBody.propTypes = {
    attributes: PropTypes.array,
    category: PropTypes.object,
    filterOptions: PropTypes.array,
    // isOpen: PropTypes.bool,
    // onClick: PropTypes.func,
    onFileClick: PropTypes.func
};

export default withI18n()(ItemDataCategoryBody);
