import React, { Component } from 'react';
import { withI18n, Trans } from '@lingui/react';
import { scroller } from 'react-scroll';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import cx from 'classnames';

import * as c from '../constants';
import { getCategoryButtonStatus } from '../helpers';

const categories = Object.values(c.ARTICLE_DETAIL_SECTIONS);

export class ArticleDetailsCategoryTab extends Component {
    handleClick = key => {
        const { onTabClick, scrollOnClick, scrollContainer } = this.props;

        onTabClick(key);

        if (scrollOnClick) {
            let scrollOptions = {
                duration: 500,
                delay: 50,
                smooth: 'easeInOutQuint'
            };

            if (scrollContainer) {
                scrollOptions.containerId = scrollContainer;
            }

            // Per dare tempo all'elemento di essere renderizzato
            setTimeout(() => {
                scroller.scrollTo(`category-${key}`, scrollOptions);
            }, 100);
        }
    };

    render() {
        const { activeCategories, details } = this.props;

        const categoryButtonStatus = getCategoryButtonStatus(details);

        return (
            <div className="column col-12">
                {sortBy(categories, 'order').map(category => (
                    <div className="article-category-tab-btn" key={category.key}>
                        <button
                            className={cx('btn', 'btn-block', 'btn-article-detail-section', {
                                'btn-primary': includes(activeCategories, category.key),
                                disabled: categoryButtonStatus[category.key] === false
                            })}
                            onClick={this.handleClick.bind(this, category.key)}
                        >
                            <Trans id={category.shortLabel} />
                        </button>
                    </div>
                ))}
            </div>
        );
    }
}

ArticleDetailsCategoryTab.defaultProps = {
    scrollOnClick: false
};

export default withI18n()(ArticleDetailsCategoryTab);
