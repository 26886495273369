import { createElement, Component } from 'react';
import throttle from 'lodash/throttle';

const scrollPosHandler = containerId => Target => {
    class ScrollPosHandler extends Component {
        constructor(props) {
            super(props);

            this.state = {
                scrollPos: 0
            };

            this.updateScrollPosition = throttle(
                this.updateScrollPosition,
                500
            );
        }

        onMount = () => {
            this.container =
                containerId === 'body'
                    ? window
                    : document.getElementById(containerId);
            this.ticking = false;

            if (this.container) {
                this.container.addEventListener('scroll', this.onScroll);
            }
        };

        componentWillUnmount() {
            this.updateScrollPosition.cancel();

            if (this.container) {
                this.container.removeEventListener('scroll', this.onScroll);
            }
        }

        updateScrollPosition = scrollPos => {
            this.setState({
                scrollPos
            });
        };

        onScroll = e => {
            // console.warn('IM SCROLLING');

            if (!this.ticking) {
                window.requestAnimationFrame(() => {
                    // console.warn(this.container.scrollY);
                    this.updateScrollPosition(
                        containerId === 'body'
                            ? this.container.scrollY
                            : this.container.scrollTop
                    );
                    this.ticking = false;
                });

                this.ticking = true;
            }
        };

        render() {
            return createElement(Target, {
                ...this.props,
                onMount: this.onMount,
                scrollPos: this.state.scrollPos
            });
        }
    }

    return ScrollPosHandler;
};

export default scrollPosHandler;
