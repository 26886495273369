import { call, put, select, fork, takeEvery } from 'redux-saga/effects';
import { hash, fromJS } from 'immutable';

import { actionTypes, actions } from '../actions/sectorsActions';
import { selectors /*treeUtils, NodeRecord*/ } from '../reducers/sectorsReducer';
import { actions as productClassesActions } from '../actions/productClassesActions';
import { actions as filtersActions } from '../actions/filtersActions';
import { selectors as productClassesSelectors } from '../reducers/productClassesReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
// import { setTree } from '../actions/sectorsActions';
import { fetchClassificationTree } from '../api';
import { fetchItems } from '../actions/itemsActions';
import * as constants from '../constants';

const classificationDependentFilters = [
    // constants.CATALOG_FILTER,
    constants.LINE_FILTER,
    constants.MODEL_FILTER,
    constants.SERIES_FILTER
];

function* fetchSectorsSaga(action) {
    yield put(filtersActions.resetFilters(classificationDependentFilters));
    // yield put(productClassesActions.resetProductClass());
    // yield put(actions.resetIdrolabClassification());
    yield put(actions.setSelectedItemTree(null));
    yield put(productClassesActions.setProductClassValue(null));

    // FIXME: necessario questo controllo perche nel caso la classificazione non sia quella di default
    // il re-fetch dell'albero viene già effettuato all'interno della saga "fetchItemsSaga" e quindi
    // risulterebbe una doppia chiamata
    if (action.payload.value === constants.DEFAULT_CLASSIFICATION) {
        yield fork(fetchTree, true);
    }
}

export function* fetchTree(showLoading = false) {
    const apiPayload = yield select(filtersSelectors.getApiPayload);
    const apiHash = hash(fromJS(apiPayload));

    const isFetching = yield select(selectors.getIsFetching);
    const currentApiHash = yield select(selectors.getCurrentApiHash);

    if (isFetching === true && apiHash === currentApiHash) {
        return true;
    }

    try {
        if (showLoading) {
            yield put(actions.fetchSectorsStart());
        }
        yield put(actions.setApiHash(apiHash));

        const res = yield call(fetchClassificationTree, apiPayload);

        yield put(actions.fetchSectorsSuccess(res));
        yield put(actions.setApiHash(null));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);
    }
}

function* toggleTreeItemSaga(action) {
    // TODO: tutta questa sezione è un quasi duplicato del metodo getSelectedTreeItemRecord del file sectorsReducers
    // const stateTree = yield select(selectors.getTree);
    const classificationType = yield select(selectors.getFilterValue);

    const { id, depth } = action.payload;

    if (classificationType === constants.ETIM_CLASSIFICATION) {
        switch (depth) {
            // Se mi trovo a profondita 0 (= etim_group) allora verifico se esiste una classe etim eventualmente gia impostata nel filtro
            case 0:
                const item = yield select(selectors.getSelectedTreeItemRecord);
                const currentClass = yield select(productClassesSelectors.getFilter);

                // console.warn(item.toJS(), currentClass.toJS());

                // Se non c'è nessuna classe etim selezionata nel filtro proseguo
                if (currentClass.value === null) {
                    return true;
                }

                const checkParent = item.children.find(c => c.id === currentClass.value.value);

                // Se l'elemento selezionato nell'albero è padre della classe attualmente selezionata nel filtro proseguo
                if (checkParent) {
                    return true;
                }

                // Altrimenti imposto la classe selezionata nel filtro a null
                yield put(productClassesActions.setProductClassValue(null));

                return true;
            // Se sono a profondita 2 l'oggetto selezionato è una classe ETIM quindi invio la relativa azione
            case 1:
                yield put(actions.toggleClass(id));
                break;
            default:
                return true;
        }
    } else {
        yield put(fetchItems());
        /*let type = '';

        switch (depth) {
            case 0:
                type = 'settore';
                break;
            case 1:
                type = 'macrofamiglia';
                break;
            case 2:
                type = 'famiglia';
                break;
            default:
                break;
        }

        yield put(actions.toggleIdrolabClassification(id, type));*/
    }
    // }
}

function* toggleClassSaga(action) {
    const selectedItem = yield select(selectors.getSelectedItem);
    const productClassFilter = yield select(productClassesSelectors.getFilter);

    if (selectedItem !== null) {
        const productClass = productClassFilter.options.find(o => o.value === action.payload);

        if (productClass) {
            yield put(productClassesActions.setProductClassValue(productClass.toJS()));
        }
    } else {
        yield put(productClassesActions.setProductClassValue(null));
    }
}

/*function* toggleIdrolabClassificationSaga(action) {
    yield put(fetchItems());
}*/

export default [
    // takeEvery(actionTypes.FETCH_SECTORS, fetchSectorsSaga),
    takeEvery(actionTypes.TOGGLE_TREE_ITEM, toggleTreeItemSaga),
    takeEvery(actionTypes.TOGGLE_CLASS, toggleClassSaga),
    // takeEvery(actionTypes.TOGGLE_IDROLAB_CLASSIFICATION, toggleIdrolabClassificationSaga),
    takeEvery(actionTypes.SET_CLASSIFICATION_VALUE, fetchSectorsSaga)
];
